import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {getFirestore, doc, updateDoc} from 'firebase/firestore'; 
import { useState, useRef, useEffect } from "react";
import './NewFormAdd.css'
import ButtonFormAdd from "../buttonFormAdd";
import data from '../fields.json'
import firebaseConfig from '../firebaseConfig';
// import star from "./star.png"
import { compressImage } from "../compressFunction";
// import { sendMassage } from "../sendTelegram";
import addPhoto from './addPhoto.png'
import { useLocation, useNavigate } from "react-router";


const RedMotoNew = props => {
  //Объект который будем менять с помощью инпутов. По умолчанию подтягиваем поля из json в квадратных скобках указываем категорию объекта переданную в пропсах
  const [formItems, setFormItems] = useState(data.rentTransport.inputs)

  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location;

  useEffect(()=>{
    setFormItems(state.formItems)
    console.log("это стэйт", state)
},[state])

const back = ()=> {
  navigate(-1)
}
useEffect(() => {
  window.scrollTo(0, -80);
},[])
    //Предупреждение видимость обязательных полей
    const [visWar, setVisWar] = useState(false)

    //Предупреждение нужно добавить хотя-бы одну фотографию
    const [visPhoto, setVisPhoto] = useState(false)
      
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const db = getFirestore(app);

    const [download, setDownload] = useState(false)

    //Точки в конце "Загружаем изображения"
    const [dots, setDots] = useState(0);

    //Неактивность кнопки
    const [visButton, setVisButton] = useState(false)


    const scrollDown = () => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    }

    //Функция изменения значения
    const handleChange = (event) => {
      setFormItems((prevState) => ({
        ...prevState,
        dateAd: Date.now()
      }))
      setFormItems((prevState) => ({
        ...prevState,
        show: true
      }))
      setFormItems((prevState) => ({
        ...prevState,
        clientNotShow: false
      }))
      const { name, value } = event.target;
      setFormItems((prevState) => ({
        ...prevState,
        [name]: value
      }));
    };
  
  //Для уникальности изображения и артикул товара
  const uniquImg = String(Date.now()).slice(-5);
  
  //Изменяем кнопку input
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    // setTextDown(null)
  };

//Проверка инпутов и вывод предупреждения
const [showWarning, setShowWarning] = useState({
  brand:false,
  model:false,
  year:false,
  discription:false,
  priceDay:false,
  priceMounth:false,
})

const arrFields = ["brand","underCategory","model","year","discription","priceDay","priceMounth"]

const getTrim = () => {
  arrFields.map((field) => {
    if (!formItems[field] || formItems[field].trim() === '') {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: true
      }));
    } else {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: false
      }));
    }
    return null;
  });
};

//Функция создания Объявления
  const handleSave = async () => {
    getTrim()
    if (
    formItems.brand.trim() === '' || 
    // formItems.underCategory.trim()==='' || 
    formItems.model.trim() === '' || 
    formItems.year.trim() === '' ||
    formItems.discription.trim() === '' ||
    formItems.priceDay.trim() === '' ||
    formItems.priceMounth.trim() === '' 
    ){
      setVisWar(true)
    } else if (formItems.imageUrl.length<1) {
      setVisPhoto(true)
      setVisWar(false)
    }
    else {
    setVisButton(true)
    try {
      const docRef = await updateDocument()
      back()
      setTimeout(()=>{
        window.location.reload()
      },100)
    } catch (error) {
      setVisButton(false)
      console.error('Ошибка при добавлении документа:', error);
    }
  }
  };

//Редактирование объявления
const updateDocument = async () => {
  try {
    const documentRef = doc(db, 'market', state.id);
    const newState = { ...state, formItems: formItems };
    await updateDoc(documentRef, newState);
    console.log("ОБНОВИЛИ ДОКУМЕНТ", newState)
  } catch (error) {
    console.error('Ошибка при обновлении документа:', error);
    return false;
  }
}
    
  //Загрузка изображений
  const handleImageUpload = async (event) => {
    setDownload(true)
    //Имитация задержки
    const intervalId = setInterval(() => {
        setDots((prevDots) => prevDots < 3 ? prevDots + 1 : 0);
      }, 300);
      setTimeout(() => {
        clearInterval(intervalId);
    }, 15000);
    

    const files = event.target.files;
    const compressedImages = await compressImage(files);
    
    try {
    // Создаем ссылки на места загрузки файлов в Firebase Storage
    const storageRefs = [];
    const getImageUrls = [];
    
    for (let i = 0; i < compressedImages.length; i++) {
      const file = compressedImages[i];
      const storageRef = ref(storage, `images/${file.name+uniquImg}`);
      storageRefs.push(storageRef);
    
      // Загружаем файлы в Firebase Storage
      await uploadBytes(storageRef, file);
    
      const imageUrl = await getDownloadURL(storageRef);
      getImageUrls.push(imageUrl);
    }
    
    setFormItems((prevState) => ({
      ...prevState,
      imageUrl: [...prevState.imageUrl, ...getImageUrls]
    }));
        
    setDownload(false)
    // setTextDown("Файлы успешно загружены!");// eslint-disable-next-line
    } catch (error) {
    console.error('Ошибка при загрузке изображений:', error);
    }
    };

    
    const removeImg = (ind) => {
      setFormItems((prevState) => ({
        ...prevState,
        imageUrl: prevState.imageUrl.filter((_, index) => index !== ind)
      }));
    }
    //добавляем марждин в депозит
    const [getMargin, setGetMargin] = useState(false)
    

    return (
      <div className='contFormAdNew'>
        <div className="contAllImg">

         {formItems.imageUrl.length>0 && formItems.imageUrl.map((url, ind)=> 
         <div key={(ind+1)/3} className="divImgAd">
           <img  src={url} alt={ind} className='imgMapAd'/> 
           <div style={{position:"absolute", top:"7px", left:"7px"}}>
              <ButtonFormAdd  onClick={() => removeImg(ind)} ind={ind}></ButtonFormAdd>
           </div>
          </div> )}

          <div className='formBtnAdNew' onClick={handleButtonClick}>
            <img src={addPhoto} alt="!"/>
            <p>Добавить фото</p>
          </div>
          </div>
          <input  style={{ display: 'none' }} ref={fileInputRef} type="file"  accept=".jpg, .jpeg, .png, .HEIF" onChange={handleImageUpload} multiple placeholder='Файлы' />
         {download && <p>Загружаем изображения{'.'.repeat(dots)}</p>}
         {visPhoto ? <p className="pWarning" style={{marginTop:"0px"}} >Необходимо добавить минимум 1 фотографию</p>:
          <p style={{color:"grey", fontSize:"12px"}}>Загрузите одно или несколько изображений. Первое загруженное фото станет главным и будет отобаржаться на обложке объявления.</p>
         }
         <div style={{position:"relative"}}>
         {formItems.brand.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Марка Транспартного средства</p>}
          <input 
            className="inputAddNew" 
            placeholder="Марка транспортного средства"
            maxLength={13}
            name="brand"
            type="text"
            value={formItems.brand}
            onChange={handleChange}
            style={formItems.brand.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.brand && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.model.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Модель</p>}
          <input 
            className="inputAddNew" 
            placeholder="Модель"
            maxLength={13}
            name="model"
            type="text"
            value={formItems.model}
            onChange={handleChange}
            style={formItems.model.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.model && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.year.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Год выпуска</p>}
          <input 
            className="inputAddNew" 
            placeholder="Год выпуска"
            maxLength={4}
            name="year"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.year}
            onChange={handleChange}
            style={formItems.year.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.year && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.mileage.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Пробег</p>}
          <input 
            className="inputAddNew" 
            placeholder="Пробег"
            maxLength={6}
            name="mileage"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.mileage}
            onChange={handleChange}
            style={formItems.mileage.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.engineSize.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Объем двигателя (см3)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Объем двигателя (см3)"
            name="engineSize"
            maxLength={5}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.engineSize}
            onChange={handleChange}
            style={formItems.engineSize.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.priceDay.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена посуточно (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена посуточно (в батах)"
            maxLength={5}
            name="priceDay"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceDay}
            onChange={handleChange}
            style={formItems.priceDay.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.priceDay && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.priceWeek.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена за 1 неделю (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена за 1 неделю (в батах)"
            name="priceWeek"
            maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceWeek}
            onChange={handleChange}
            style={formItems.priceWeek.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.priceTwoWeek.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена за 2 недели (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена за 2 недели (в батах)"
            name="priceTwoWeek"
            maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceTwoWeek}
            onChange={handleChange}
            style={formItems.priceTwoWeek.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.priceMounth.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена за месяц (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена за месяц (в батах)"
            name="priceMounth"
            maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceMounth}
            onChange={handleChange}
            style={formItems.priceMounth.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.priceMounth && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.priceLong.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Условия долгосрочной аренды</p>}
          <input 
            className="inputAddNew" 
            placeholder="Условия долгосрочной аренды"
            name="priceLong"
            type='text'
            value={formItems.priceLong}
            onChange={handleChange}
            maxLength={120}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.priceLong.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.deposit.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Депозит</p>}
          <input 
            className="inputAddNew" 
            placeholder="Депозит"
            name="deposit"
            type='text'
            maxLength={120}
            value={formItems.deposit}
            onChange={handleChange}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.deposit.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.mapUrl.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Ссылка на Google maps</p>}
          <input 
            className="inputAddNew" 
            placeholder="Ссылка на Google maps"
            name="mapUrl"
            type='text'
            maxLength={1200}
            value={formItems.mapUrl}
            onChange={handleChange}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.mapUrl.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.discription.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Описание</p>}
          <textarea
            placeholder="Описание"
            className="areaAddNew" 
            name="discription"
            maxLength={1500}
            type='text'
            value={formItems.discription}
            // style={{height:height}}
            onChange={(e)=> {handleChange(e)}}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.discription.trim() !== '' ? {paddingTop:"20px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.discription && <p className="pWarning">Поле должно быть заполено</p>}
            <p style={{color:"grey", fontSize:"12px", margin:"0", marginTop:"-10px"}}>Подробно опишите предлагаемый транспорт. Укажите нюансы которые могут быть важны для арендатора или обозначьте дополнительные условия. Не указывайте контакты, ссылки на сайты или социальные сети.</p>
          {visWar && <p className="pWarning" style={{marginTop:"15px", fontSize:"14px"}}>Проверьте правильность заполнение всех обязательных полей!</p>}

          <button className="btnAddSave" onClick={handleSave} disabled={visButton} style={getMargin ? {marginBottom:"200px"}:{}}>Сохранить изменения</button>
      
      </div>
    );
  }



export default RedMotoNew