const deleteSaveAd = (key, art) => {
  console.log("Артикль!!!!", art);

  if (window.Telegram) {
    const tg = window.Telegram.WebApp;
    tg.ready();
    let value = [];

    // Функция для сохранения данных в CloudStorage
    const saveData = (newArr) => {
      tg.CloudStorage.setItem(key, JSON.stringify(newArr), (error, success) => {
        if (!error) {
          console.log('Data saved successfully');
        } else {
          console.error('Error saving data to CloudStorage', error);
        }
      });
    };

    // Проверка и добавление элемента
    const checkItems = () => {
      const index = value.indexOf(art);
      if (index !== -1) {
        value.splice(index, 1); // Удаляем элемент по индексу
        saveData(value)
      } else {
        console.log("Элемент не найден в массиве.");
      }
    };

    // Получение данных из CloudStorage
    tg.CloudStorage.getItem(key, (error, result) => {
      if (error) {
        console.error('Ошибка при получении данных из CloudStorage:', error);
        return;
      }
      if (result) {
        console.log("Данные найдены:", result);
        value = JSON.parse(result);
        checkItems();
      } else {
        console.log('Данные не найдены для ключа:', key);
      }
    });
  }
};

export default deleteSaveAd;
