import React, {useEffect, useState } from 'react';
import {collection, getFirestore, query, getDocs} from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import '../css/AllAd.css'
import CardsRentTransport from './Cards/CardsRentTransport';
import CardsSellTransport from './Cards/CardsSellTransport'
import CardsStuff from './Cards/CardsStuff';
import CardsServises from './Cards/CardsServises'
import CardsSellProperty from './Cards/CardsSellProperty';
import CardsRentProperty from './Cards/CardsRentProperty'
import RedFormRentTran from './RedForms/RedFormRentTran'
import RedFormSellTran from './RedForms/RedFormSellTran';
import RedFormServises from './RedForms/RedFormServises';
import RedFormStuff from './RedForms/RedFormStuff';
import RedFormRentProperty from './RedForms/RedFormRentProperty';
import RedFormSellProperty from './RedForms/RedFormSellproperty';
import ModerHead from './ModerHead';
import firebaseConfig from '../js/firebaseConfig';
import InfoModer from './infoModer';

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

const Moder = () => {

  const getUid =  localStorage.getItem("uid")
  //Видимость редактируемых полей
  const [getIndex, setGetIndex] = useState(null)

  const [allData, setAllData] = useState(null)
    //Фльтрация объявлений на модерации
  const [filterData, setFilterData] = useState(null)

  //Отображение информации
  const [showInfo, setShowInfo] = useState(false)

  const getInfo = () => {
    setShowInfo(true)
  }

  //фильтрация объявлений на моедрации
  const filterModer = ()=>{
    const filtredAllData = allData.filter(item => item.formItems.show === false);
    setFilterData(filtredAllData)
    setShowInfo(false)
    }
  // Отображение всех объявлений
  const allModer = () => {
    setFilterData(allData)
    setShowInfo(false)
  }

  //Поиск по артиклю
  const filterArt = (artSearch) => {
    const filtredAllData = allData.filter(item => Number(item.formItems.art) === Number(artSearch));
    setFilterData(filtredAllData)
  }

    useEffect(()=>{  

        // Функция для получения данных пользователя по его id
        const getAllData = async () => {
          try {
            const usersCollectionRef = collection(db, 'market');
            // const q = query(usersCollectionRef, where('formItems.show', '==', false));
            const q = query(usersCollectionRef);
            const querySnapshot = await getDocs(q);
        
            if (querySnapshot.empty) {
              console.log('Данные не найдены');
              return null;
            }
        
            const getAllData = [];
            querySnapshot.forEach((doc) => {
            getAllData.push({ id: doc.id, ...doc.data() });
            });
            // setFilterData(getAllData.formItems)
            // setAllData(getAllData.formItems)
            
            return getAllData;
          } catch (error) {
            console.error('Ошибка при получении данных пользователя:', error);
            return null;
          }
        };

        //Функция отображения объявлений
        const allAdd = () => {
            getAllData().then((getAllData) => {
          if (getAllData) {
            const sortedData = [...getAllData];
            sortedData.sort((a, b) => {
                return  b.formItems.dateAd - a.formItems.dateAd;
            });
            setAllData(sortedData)
            setFilterData(sortedData)
            // Здесь вы можете выполнить необходимые действия с данными пользователя
          }
        });
        }
        allAdd()
        },[])

//Нажатие кнопки редактировать
        const forRed = (index) =>{
            setGetIndex(index)
          }
          const closeRed = () => {
            setGetIndex(0.7)
           }

    if (getUid === 'xKJhIJNjuPVdomdQkZBbVPWW1sx2' || getUid === 'uc8se9WZpgMyvErPPcAPPE1HRbG2') {
    return (
        <div className="contAllAd">
          <ModerHead filterArt={filterArt} filterModer={filterModer} allModer={allModer} getInfo={getInfo}/>
          {!showInfo ? 
            <div className="cards" >
                {filterData && filterData.map((ad, index) => 
                <div key={index}>
                   <p style={{color:"#747474", marginLeft:"5px"}}>Аритикль: {ad.formItems.art}</p>
                   {ad.formItems.category==='rentTransport' ? <CardsRentTransport ad={ad.formItems}/>: 
                     ad.formItems.category==='sellTransport' ? <CardsSellTransport ad={ad.formItems}/>:
                     ad.formItems.category==='servises' ? <CardsServises ad={ad.formItems}/>:
                     ad.formItems.category==='stuff' ? <CardsStuff ad={ad.formItems} />:
                     ad.formItems.category==='rentProperty' ? <CardsRentProperty ad={ad.formItems} />:
                     ad.formItems.category==='sellProperty' ?<CardsSellProperty ad={ad.formItems} />:
                     <div/>}
                    
                    <button onClick={()=>forRed(index)} className='btnRed'>Редактировать</button>
                    {index===getIndex && ad.formItems.category==='rentTransport' && <RedFormRentTran key={index} add={ad} onClick={closeRed}/>}
                    {index===getIndex && ad.formItems.category==='sellTransport' && <RedFormSellTran key={index} add={ad} onClick={closeRed}/>}
                    {index===getIndex && ad.formItems.category==='servises' && <RedFormServises key={index} add={ad} onClick={closeRed}/>}
                    {index===getIndex && ad.formItems.category==='stuff' && <RedFormStuff key={index} add={ad} onClick={closeRed}/>}
                    {index===getIndex && ad.formItems.category==='rentProperty' && <RedFormRentProperty key={index} add={ad} onClick={closeRed}/>}
                    {index===getIndex && ad.formItems.category==='sellProperty' && <RedFormSellProperty key={index} add={ad} onClick={closeRed}/>}
                </div>)}
            </div> : <InfoModer/>}
        </div>
    )
} else {
  return (
  <div>Доступ запрещен</div>
  )
}

} 


export {Moder}