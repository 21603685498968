import '../css/Search.css'
// import eye from '../image/eye.png'
import rentAuto from '../image/rentAuto1.png'
import sellAuto from '../image/sellAuto1.png'
import rentBike from '../image/rentBike1.png'
import sellBike from '../image/sellBike1.png'
import service from '../image/serice1.png'
import rentHouse from '../image/rentHouse1.png'
import sellHouse from '../image/sellHouse1.png'
import gods from '../image/gods1.png'
// import { Link } from 'react-router-dom'

const ListCategories = () => {

    return (
        <div>
            <div className="eachList">
                <img src={rentAuto} alt="o" className="imgList" />
                <p className="textList">Аренда автомобилей</p>
            </div>
            <div className="eachList">
                <img src={rentBike} alt="o" className="imgList" />
                <p className="textList">Аренда байков</p>
            </div>
            <div className="eachList">
                <img src={sellAuto} alt="o" className="imgList" />
                <p className="textList">Продажа автомобилей</p>
            </div>
            <div className="eachList">
                <img src={sellBike} alt="o" className="imgList" />
                <p className="textList">Продажа байков</p>
            </div>
            <div className="eachList">
                <img src={rentHouse} alt="o" className="imgList" />
                <p className="textList">Аренда Недвижимости</p>
            </div>
            <div className="eachList">
                <img src={sellHouse} alt="o" className="imgList" />
                <p className="textList">Продажа недвижимости</p>
            </div>
            <div className="eachList">
                <img src={gods} alt="o" className="imgList" />
                <p className="textList">Товары</p>
            </div>
            <div className="eachList" style={{borderBottom:"1px solid rgb(194, 194, 194)", paddingBottom:"5px"}}>
                <img src={service} alt="o" className="imgList" />
                <p className="textList">Услуги</p>
            </div>
        </div>
    )
}

export default ListCategories