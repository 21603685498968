const firebaseConfig = 
// {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: "rental-agregator.firebaseapp.com",
//     projectId: "rental-agregator",
//     storageBucket: "rental-agregator.appspot.com",
//     messagingSenderId: "606713530290",
//     appId: "1:606713530290:web:47b7b198f43fb27be086ab"
//   };

  {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "tgmarketplace-65e22.firebaseapp.com",
  projectId: "tgmarketplace-65e22",
  storageBucket: "tgmarketplace-65e22.appspot.com",
  messagingSenderId: "910627862678",
  appId: "1:910627862678:web:331fa3857a7c666543eab7",
  }

export default firebaseConfig