import '../css/ModalAlert.css'

const ModalAlert = props => {

    const closeModal = () => {
        props.closeModalAlert()
    }
    const deleteAd = () => {
        props.deleteDocument()
    }

    return (
        <div className="modalHidden">
            <div className="modalBlock">
                <p className="alertDesc">Вы уверены что хотите удалить объявление без возможности восстановления?</p>
                <div className="contButtonsAlert">
                    <button onClick={deleteAd} className="btnDelete">Удалить</button>
                    <button onClick={closeModal} className="btnCancle">Отменить</button>
                </div>
            </div>
        </div>
    )
}

export default ModalAlert