
import { doc, updateDoc, getFirestore } from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import firebaseConfig from "./firebaseConfig";

// Инициализируем Firebase приложение и Firestore только один раз
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const redAdFunction = async (id, object) => {
    try {
        console.log("Изф функции АЙД", id)
        console.log("Изф функции объйктушка", object)
        const documentRef = doc(db, 'market', id);
        await updateDoc(documentRef, object);
        window.location.reload();
    } catch (error) {
        console.error('Ошибка при обновлении документа:', error);
        return false;
    }
}

export default redAdFunction;
