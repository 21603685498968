import { useState, useEffect } from 'react';
import './AdForm.css'
import whatsapp from'./whatsapp.png'
import telegram from'./telegram.png'
import { useNavigate } from 'react-router-dom';
// import scrollRighr from './scroll.png'
import back from './back.png'
import { Helmet, HelmetProvider} from 'react-helmet-async';
import { YMInitializer } from 'react-yandex-metrika';

const AdSellMoto = ad => {

    useEffect(() => {
        window.scrollTo(0, -80);
      },[])
    
    const [visContact, setVisContact] = useState(false)
    
    //Возврат на предыдущую страницу
    const navigate = useNavigate();
        const goBack = () => {
          navigate(-1);
        };

    const moment = require('moment');
    require('moment/locale/ru');

    if (ad.ad.dateAd) {
    var d = moment(ad.ad.dateAd).format('LL')}
    else {d=null}

    let marginImg
    if (ad.ad.imageUrl.length===1) {
        marginImg=null
    } else {
        marginImg='1%'
    }


    return (
        <HelmetProvider>
        <>
        <YMInitializer accounts={[94307804]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
          <Helmet>
             <title>Продам {ad.ad.brand} {ad.ad.model} {ad.ad.year} года выпуска. Пробег - {ad.ad.mileage}км. Цена: {ad.ad.price} Бат.</title>
             <meta name="description" content={ad.ad.discription} />
        </Helmet>
        <div style={{display:"flex", flexDirection:"row", alignItems:"center", paddingTop:"1%"}}>
          <img onClick={goBack} className='imgBack' src={back} alt="-" />
          <h1 style={{marginRight:"7%", fontSize:"24px"}}>Продам:  {ad.ad.brand} {ad.ad.model}</h1>
        </div>
            <div className='contAdForm'>
                <div className='adContText'>
                <p className='timeAd' >{d}</p>
                    <p className='modalInfo'>Год выпуска: {ad.ad.year}г. </p>
                    {ad.ad.mileage && <p className='modalInfo'>Пробег: {ad.ad.mileage}км</p>}
                    {ad.ad.engineSize && <p className='modalInfo'>Объем: {ad.ad.engineSize}cm3</p>}
                    <br/>
                    <p style={{color:'#272727'}} className='modalInfo'>{ad.ad.discription}</p>

                    <div className='adPrices'>
                    <p className='modalInfo' style={{color:'#272727',fontWeight:'bold'}}>Цена: {ad.ad.price} Бат</p>
                    </div>
                    <button onClick={()=>setVisContact(true)} className='adButton'>Показать контакты</button>
                    {visContact&& <div>
                        {ad.ad.whatsapp && <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"15px"}}>
                            <img src={whatsapp} alt='W' style={{width:'30px', height:'30px', marginRight:"15px"}} />
                            <a href={`https://wa.me/${ad.ad.whatsapp}`} target='_blank' rel="noreferrer" style={{margin:'0', color:"#116466"}}>{ad.ad.whatsapp}</a>
                        </div>}
                        {ad.ad.telegram && <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:"15px"}}>
                            <img src={telegram} alt='W' style={{width:'30px', height:'30px', marginRight:"15px"}} />
                            <a href={`https://t.me/${ad.ad.telegram}`} target='_blank' rel="noreferrer" style={{margin:'0', color:"#116466"}}>@{ad.ad.telegram}</a>
                        </div>}
                    </div>}
                </div>
                <div className='eachImgCont'>
                    {ad.ad.imageUrl && ad.ad.imageUrl.map((url, index)=> 
                    <img  style={{marginRight:marginImg}} key={index} className='eachImg'  src={url} alt="Изображения не добавлены"/>
                    // <img  style={ad.ad.imageUrl.length>1 ? {marginLeft:marginImg, width:'85%'}: {width:"100%", margin:"0"}} key={index} className='eachImg'  src={url} alt="Изображения не добавлены"/>
                    )}
                </div>
            </div>
     </>
     </HelmetProvider>

    )
}
export {AdSellMoto}