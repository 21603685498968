import {Link} from "react-router-dom";
import '../css/NotFound.css'
import { Helmet, HelmetProvider} from 'react-helmet-async';

const NotFound = () => {
    return (
        <HelmetProvider>
        <div className='contNotFound'>
        <Helmet>
           <title>Ошибка 404. Страница не найдена</title>
           <meta name="description" content="Страница не найдена или удалена." />
      </Helmet>
            <h3>404</h3>
            <h4>Страница не найдена</h4>
            <p>Cтраница, на которую вы пытаетесь попасть, не существует или была удалена.</p>
            <p>Перейдите на <Link to='/' style={{color:"#116466"}}>Главную страницу</Link></p>
        </div>
        </HelmetProvider>
    )
}
 export {NotFound}