
import  Resizer  from 'react-image-file-resizer';

export async function  compressImage (files){
    const compressedImages = [];
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // const a = 500
      // const compress =a/((file.size*1024)/300)
      
      if (file.size > 90 * 1024) { 
        const compressedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            1080, // оставляем прежнюю ширину изображения
            1080, // оставляем прежнюю высоту изображения
            'JPEG', // формат изображения
            30, // качество сжатия (от 0 до 100)
            0, // поворот изображения (в градусах)
            (blob) => {
              resolve(blob);
            },
            'blob' // выходной тип данных ('blob', 'base64' или 'file')
          );
        });
        
        const filenameWithIndex = file.name.replace(/\.[^/.]+$/, '') + '_' + i + file.name.match(/\.[^/.]+$/);
        const compressedFile = new File([compressedImage], filenameWithIndex, { type: compressedImage.type });
  
        compressedImages.push(compressedFile);
        console.log(file.name)
      } else {
        compressedImages.push(file); // Добавляем оригинальный файл без сжатия
      }
    }
    
    return compressedImages;
  };