import './CardsAd.css'


const CardsRentTransport = props => {

    const moment = require('moment');

    require('moment/locale/ru');
    
    if (props.ad.dateAd) {
        const dateAd = moment(props.ad.dateAd).format('YYYYMMDD HH:mm:ss');
        var spentTime = moment(dateAd, "YYYYMMDD hh:mm:ss").fromNow()
    } else spentTime=null


    if (props.ad) {
    return (

    <div  className="card">
        <div className="containerDiv">
        {spentTime && <p className="timeAgo">{spentTime}</p>}
          {props.ad.imageUrl && 
             <div className="imageCont">
                    <img src={props.ad.imageUrl[0]} alt="Фото не добавлено"  />
                    <div className="contentAd">
            <h3 >{props.ad.brand} {props.ad.model}</h3>
            <div className="divHidden">
            <p style={{fontWeight:"bold"}}>{props.ad.year}г.</p>
            <p style={{fontWeight:"bold"}}>{props.ad.mileage}км.</p>
            </div> 
            <div className="divHidden">
            <p >{props.ad.discription}</p>
            </div>     
            <p style={{color:"#272727", fontWeight:"bold"}}>{props.ad.price} Бат </p>   
        </div>
             </div>}
        </div>

    </div>

    )} else {
        return (<div></div>)
    }
}
export default CardsRentTransport