import '../css/Rules.css'
import { Helmet, HelmetProvider} from 'react-helmet-async';
// import back from '../image/back.png'
// import { useNavigate } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

const AboutUs = () => {

      //Возврат на предыдущую страницу
    //   const navigate = useNavigate();
    //   const goBack = () => {
    //     navigate(-1);
    //   };

    return (
        <HelmetProvider>
        <div className='rulesCont'>
        <YMInitializer accounts={[94307804]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
        <Helmet>
           <title>О сайте</title>
           <meta name="description" content="Мы рады представить вам уникальную платформу для размещения бесплатных объявлений о продаже и аренде транспортных средств, услуг, и товаров на продажу." />
      </Helmet>
        {/* <div style={{display:"flex", flexDirection:"row"}}>
            <img onClick={goBack} className='imgBackRules' src={back} alt="-" /> */}
            <h1 className='rulesH3'>О сайте</h1>
        {/* </div> */}
            
            <p style={{fontSize:"18px"}} className="rulesH4">Добро пожаловать на веб-портал Aponga.com!</p>
            <div>
                <p>Мы рады представить вам уникальную платформу для размещения бесплатных объявлений о продаже и аренде транспортных средств, услуг, и товаров на продажу.</p>
                <p>Наша компания особенно гордится тем, что мы создали эту платформу специально для русскоговорящих людей, находящихся за границей. </p>
                <li >Мы понимаем, что находясь в другой стране, вам может быть сложно найти подходящий ресурс, который соответствует вашим потребностям и позволяет вам легко и безопасно разместить объявления на вашем родном языке.</li>
                <p>Пользуясь нашим сайтом Aponga.com, вы сможете разместить объявление о продаже или аренде транспортных средств любого типа - автомобилей, мотоциклов и других. </p>
                <li>Мы также предлагаем возможность размещения объявлений о продаже различных услуг - от красоты и здоровья до обучения и фитнеса.</li>
                <li>Кроме того, вы можете предложить на продажу разнообразные товары - от электроники и мебели до одежды и аксессуаров.</li>
                <p>Особенность нашей платформы заключается в том, что мы предлагаем размещение объявлений бесплатно.</p>
                <p>Надеемся, что наша платформа Aponga.com будет полезным инструментом для вас, помогая вам найти то, что вам нужно, и связаться с другими русскоговорящими людьми во всем мире. </p>
                <p>Присоединяйтесь к нашему сообществу и начните размещать свои объявления прямо сейчас!</p>
                <p>Если у вас возникнут вопросы или пожелания, не стесняйтесь связаться с нами. Мы всегда готовы помочь вам.</p>
                <p style={{color:"#116466"}}>Спасибо за выбор Aponga.com!</p>
            </div>
        </div>
        </HelmetProvider>
    )
}
 export {AboutUs}