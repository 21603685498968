import '../css/Profile.css'
import back from '../image/leftBl.png'
import { useLocation, useNavigate } from 'react-router'
import sell from '../image/user.png'
import users from '../image/folowerUsers.png'
import ads from '../image/folowerAdd.png'
import clock from '../image/clock.png'
import favorite from '../image/favorite.png'
import dots from '../image/dots.png'
import CreateAddModal from './CreateAddModal';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import getSaveAds from './getSaveAds'
import redAdFunction from './redAdFunction'


const Profile = props => {
    const navigate = useNavigate()
    const location = useLocation()
    const moment = require('moment');
    require('moment/locale/ru');

    const stepBack = () => {
        navigate(-1)
    }
    //Данные пользователя телеграмм
    const [userData, setUserData] = useState(null)
    useEffect(() => {
        //  if (window.Telegram) {
        //       const tg = window.Telegram.WebApp;
        //       tg.ready();
        //       setUserData(tg.initDataUnsafe.user)
        //     } 
                const u = {
                    username:"Leonid2323",
                    first_name:"Leonid",
                    last_name:"Sushkov"
                }
                console.log(u)
                setUserData(u)
            
        }, []);

    const [myAds, setMyAds] = useState(null)

    //Сохраненные объявления
    const [listData, setListData] = useState(null)
    useEffect(() => {
        const fetchData = async () => {
          const arr = await getSaveAds("arrArts1")
        //   console.log("Вытащенные данные из локал стораге!!!", arr);
          if (arr) {
            // console.log("Вытащенные данные из локал стораге", arr);
            setListData(arr)
          }
        };
    
        fetchData();
      }, [props]);
      
//Мои объявления
useEffect(() => {
    const fetchData = () => {
      const allAds = props.allData;
      let userName = null
      if (userData) {
      userName = userData.username;
      }
    // const userName = "Leonid2323";
      console.log("ИМЯ!!!", userName)
      if (allAds && userName) {
        console.log("Все данные из props:", allAds);
        console.log("Имя пользователя:", userName);
        const filteredAds = allAds.filter(ad => ad.formItems.telegram === userName);
        setMyAds(filteredAds);
        console.log("Отфильтрованные объявления:", filteredAds);
      }
    };
  
    fetchData();
  }, [props, userData]);

  //Редактирование объявления (продано, не продано)
  const changeShow = (show, ad) => {
    console.log("Нажали кнопку", show)
    const updatedAd = {
      ...ad,
      formItems: {
        ...ad.formItems,
        clientNotShow: show
      }
    };
    const newData = { ...updatedAd, formItems: updatedAd.formItems };
    
    redAdFunction(updatedAd.id, newData)
  };

    const [visModal, setVisModal] = useState(false)
    const getModal = () => {
        setVisModal(!visModal)
    }

    
    useEffect(()=>{
        const { state } = location;
        setVisModal(state)
    },[])

    const goToSaveUsers = () => {
        navigate('/savepropfiles')
    }

    const goToRed = (ad) => {
        navigate("/redad", {state:ad})
    }

    const goToAd = (url) => {
        navigate(url)
    }


    return (
        <div>
            {visModal && <CreateAddModal getModal={getModal}/>}
            <div className="contHeadProfile">
                <img src={back} alt="<" className="profileBackImg" onClick={stepBack}/>
                <p style={{fontSize:"18px"}}>Вы</p>
            </div>
            {userData ?
            <div className="contName">
                <img src={sell} alt="U" className="imgProfile"/>
                {/* <img src={props.userData.photo_url ? props.userData.photo_url : sell} alt="U" className="imgProfileTg"/> */}
                <p className="textName">{userData.first_name} {userData.last_name}</p>
            </div>:
            <div className="contName">
                <img src={sell} alt="U" className="imgProfile"/>
                {userData ? <p>{userData.first_name} {userData.last_name}</p>: <p className="textName">Пользователь Телеграм</p>}
            </div>
}
    <div className="contFavoriteProfile">
        <div className="twoBlocksProfile">
        <Link to={listData ? "/saveads":"#"} style={{textDecoration:"none", color:"black", width:"49%"}}>
            <div className="leftElement" style={{width:"100%"}}>
                <img className="imgElementProfile" src={favorite} alt="o"/>
                {listData ? <p className="textElementProfile">{listData.length} сохраненные объявления</p>:<p className="textElementProfile">Нет сохраненных объявлений</p>}
            </div>
        </Link>
            <div className="leftElement">
                <img className="imgElementProfile" src={clock} alt="o" style={{width:"23px", height:"23px"}}/>
                <p className="textElementProfile">Недавно просмотренные</p>
            </div>
        </div>
        <div className="twoBlocksProfile" style={{borderBottom:"1px solid rgb(236, 236, 236)", paddingBottom:"3%"}}>
            <div className="leftElement" onClick={goToSaveUsers}>
                <img className="imgElementProfile" src={users} alt="o"/>
                <p className="textElementProfile">Подписки на пользователей</p>
            </div>
            <div className="leftElement">
                <img className="imgElementProfile" src={ads} alt="o" style={{width:"23px", height:"23px"}}/>
                <p className="textElementProfile">Подписки на объявления</p>
            </div>
        </div>
    </div>
    <p style={{fontSize:"18px", fontWeight:"600", marginLeft:"3%"}}>Ваши объявления</p>
    <div className="allMyAds">
                {myAds ? myAds.map((ad, id) => (
                    <>
                    <div className="eachmyAd" key={id} style={{marginBottom:"0px"}}>
                    <div style={{color:"black",display:"flex", width:"100%"}}>
                        <div style={{position:"relative", width:"18%", paddingTop:"18%", borderRadius:"10px", marginRight:"3%"}}>
                         <img style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%"}} src={ad.formItems.imageUrl[0]} alt="?" className="imgMyAds" onClick={()=>goToAd(`/ad${ad.formItems.art}`)}/>
                         </div>
                    <div className="contTextMyAds" onClick={()=>goToAd(`/ad${ad.formItems.art}`)}>
                        <p className="text1MyAd">{ad.formItems.year} {ad.formItems.brand} {ad.formItems.model}</p>
                        {ad.formItems.clientNotShow ? <p className="text2MyAd" style={{color:"red"}}>Продано {!ad.formItems.show && <span style={{color:"#1877F2"}}>На модерации</span>}</p>:<p className="text2MyAd">Доступно {!ad.formItems.show && <span style={{color:"#1877F2"}}>На модерации</span>}</p>} 
                        <p className="text3MyAd">Сохранено {moment(ad.formItems.dateAd).format('LL')}</p>
                    </div>
                    </div>
                    <img src={dots} className="imgMyAdsRight" alt="..." onClick={()=>{goToRed(ad)}}/>
                    </div>
                    {ad.formItems.clientNotShow ? <div className="btnSell" style={{backgroundColor:"rgb(218, 218, 218)"}} onClick={()=> changeShow(false, ad)}>Снять отметку "продано"</div>: <div className="btnSell" onClick={()=> changeShow(true, ad)}>Отметить как "продано"</div>}
                    <div className="btnPromote" >Продвигать объявление"</div>
                    </>
                )
            ):
                <p style={{}}>Нет созданных объявлений</p>
            }
    </div>
    <div style={{width:"94%", marginLeft:"3%", marginRight:"3%", borderTop:"1px solid rgb(194, 194, 194)"}}>
        <div className="btnAdd" onClick={getModal}>
            <p>Создать объявление</p>
        </div>
    </div>
        </div>
    )
}

export {Profile}