import '../css/Rules.css'
import { Link, useNavigate} from "react-router-dom"
import { Helmet, HelmetProvider} from 'react-helmet-async';
import { YMInitializer } from 'react-yandex-metrika';

const Terms = () => {

    let navigate = useNavigate()
    const getTerms = () => {
        navigate(-1);
    }

    return (
      <HelmetProvider>
        <div className='rulesCont'>
        <YMInitializer accounts={[94307804]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
      <Helmet>
           <title>Пользовательское соглашение и политика конфиденциальности для сайта Aponga.com</title>
           <meta name="description" content="Пожалуйста, внимательно ознакомьтесь с нашим пользовательским соглашением и политикой конфиденциальности." />
      </Helmet>
            <h1 className='rulesH3'>Пользовательское соглашение</h1>
            <p style={{fontSize:"18px"}} className='rulesH4'>Пользовательское соглашение и политика конфиденциальности для сайта Aponga.com</p>
            <p style={{color:"#747474"}}>Пожалуйста, внимательно ознакомьтесь с нашим пользовательским соглашением и политикой конфиденциальности ниже.
            <br/>Этот документ содержит правила и условия использования сайта Aponga.com, а также объясняет, как мы собираем, используем и защищаем вашу личную информацию. При использовании нашего сайта, вы соглашаетесь с этими правилами и условиями. Если вы не согласны с этими условиями, просим вас прекратить использование нашего ресурса.</p>
            <ul >
                <li style={{color:"#272727"}}>Регистрация и использование аккаунта:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>При регистрации на сайте Aponga.com, вы обязаны предоставить достоверные и актуальные данные о себе.Ваш аккаунт на сайте Aponga.com предназначен только для вашего личного использования. Вы несете ответственность за сохранность вашего пароля и конфиденциальности вашей информации.</p>
                    <li style={{color:"#272727"}}>Собираемая информация:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>При использовании нашего сайта, мы можем собирать определенную информацию, включая ваше имя, адрес электронной почты, номер телефона и другие контактные данные.Мы также можем собирать информацию, связанную с вашим взаимодействием с нашим сайтом, такую как IP-адрес, данные о браузере, информацию о сеансе и т.д.Мы не собираем информацию, относящуюся к вашим финансовым счетам, таким как номера кредитных карт или банковские реквизиты. Для онлайн-платежей мы используем платежные системы сторонних поставщиков, которые обеспечивают безопасность ваших платежных данных.</p>
                    <li style={{color:"#272727"}}>Использование информации:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Мы используем собранную информацию для обеспечения функциональности и улучшения нашего сайта, предоставления вам услуг, обработки платежей, связи с вами и предоставления поддержки пользователей.Мы можем использовать вашу информацию для отправки вам информационных и маркетинговых материалов, связанных с нашими услугами, но только с вашего согласия. Вы можете отказаться от получения этих материалов в любое время.</p>
                    <li style={{color:"#272727"}}>Разглашение информации:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Мы не продаем, не арендуем и не передаем вашу личную информацию третьим сторонам без вашего согласия, за исключением случаев, предусмотренных законом или связанных с обеспечением наших услуг.Мы можем разглашать вашу информацию третьим сторонам, которые работают от нашего имени, в целях обработки платежей, предоставления поддержки пользователей или анализа данных. Все третьи стороны, с которыми мы сотрудничаем, обязаны соблюдать конфиденциальность вашей информации.</p>
                    <li style={{color:"#272727"}}>Права и ответственность:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Aponga.com является платформой для размещения объявлений, и мы не несем ответственности за качество товаров, услуг или транспортных средств, предлагаемых нашими пользователями.Вы полностью несете ответственность за свои действия на сайте Aponga.com, включая взаимодействие с другими пользователями и совершение сделок.</p>
                    <li style={{color:"#272727"}}>Право на удаление контента:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Aponga.com оставляет за собой право удалить или отредактировать любой контент, размещенный на сайте, который нарушает наше пользовательское соглашение или противоречит законодательству.Мы также оставляем за собой право заблокировать или удалить аккаунт пользователя, нарушающего наши правила и условия.</p>
                    <li style={{color:"#272727"}}>Безопасность данных:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Мы принимаем все необходимые меры для обеспечения безопасности вашей информации и защиты от несанкционированного доступа, использования или разглашения.Мы используем шифрование данных и применяем физические, технические и организационные меры безопасности для защиты вашей информации от потери, кражи или неправомерного использования.</p>
                    <li style={{color:"#272727"}}>Интеллектуальная собственность:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Все права на содержимое и дизайн сайта Aponga.com принадлежат нам или нашим лицензиаром. Запрещается копирование, распространение или использование нашего контента без нашего письменного разрешения.</p>
                    <li style={{color:"#272727"}}>Ссылки на сторонние сайты:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Наш сайт может содержать ссылки на сторонние веб-сайты, которые не контролируются нами. Мы не несем ответственности за политику конфиденциальности или практики безопасности этих сторон. Рекомендуется ознакомиться с политиками конфиденциальности этих веб-сайтов перед предоставлением им своей личной информации.</p>
                    <li style={{color:"#272727"}}>Возрастные ограничения:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Наш сайт предназначен для использования только лицами, достигшими возраста, необходимого для заключения юридически обязательных соглашений в их стране проживания. Мы не собираем информацию от несовершеннолетних.</p>
                    <li style={{color:"#272727"}}>Изменение пользовательского соглашения и политики конфиденциальности:</li>
                    <p style={{color:"#747474", marginTop:"0px"}}>Мы оставляем за собой право внести изменения в это пользовательское соглашение и политику конфиденциальности в любое время. Изменения будут размещены на нашем сайте, и ваше дальнейшее использование ресурса будет означать ваше согласие с обновленными правилами и условиями.</p>
          </ul>
            <p style={{color:"#272727"}}>Если у вас есть вопросы или нужна дополнительная информация, пожалуйста, обратитесь к нашему разделу <Link to="/feedback" style={{color:"#116466",}}>"Свяжитесь с нами"</Link>.</p>
          <p style={{color:"#116466"}}>Спасибо за использование Aponga.com!</p>
          <button onClick={getTerms} className="btnTerms">Принять все условия</button>
        </div>
        </HelmetProvider>
    )
}
export {Terms}