import './CardsAd.css'

const CardsRentTransport = props => {

const moment = require('moment');

require('moment/locale/ru');

if (props.ad.dateAd) {
    const dateAd = moment(props.ad.dateAd).format('YYYYMMDD HH:mm:ss');
    var spentTime = moment(dateAd, "YYYYMMDD hh:mm:ss").fromNow()
} else spentTime=null

    if (props.ad) {
    return (

    <div  className="cardEach">
          {props.ad.imageUrl && 
        <div className="imageCont">
            <img src={props.ad.imageUrl[0]} alt="Фото не добавлено"  />
        </div>}
        <div className="contentAd">
            <h3 >{props.ad.priceDay} ฿ · {props.ad.brand} {props.ad.model}</h3>
        </div>
    </div>

    )} else {
        return (<div></div>)
    }
}
export default CardsRentTransport