export async function sendMassage(id, message, url) {
    try {
      console.log("Значение ид", id)
      const chatIds = [id]; // Список идентификаторов чатов
      const messageText = message
      const token = process.env.REACT_APP_TOKEN_TG1

      const responsePromises = chatIds.map(async (chatId) => {
        const response = await fetch(`https://api.telegram.org/${token}/sendMessage`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: messageText,
            parse_mode: 'HTML',
            reply_markup: {
              inline_keyboard: [
                [
                  {
                    text: 'Посмотреть объявление',
                    url: url
                  }
                ]
              ]
            }
          }),
        });
        
        if (!response.ok) {
          console.log(`Не удалось отправить сообщение в чат ${chatId}`);
        }
      });
  
      await Promise.all(responsePromises);
  
      // window.location.reload();
    } catch (error) {
      console.log('Error:', error);
      // Обработка других ошибок
    }
  }