import "../css/Rules.css"
import React, {useState} from 'react';
import { Helmet, HelmetProvider} from 'react-helmet-async';
import { YMInitializer } from 'react-yandex-metrika';

const FeedBack = () => {

    const [getMassage, setGetMassage] = useState('')
    const [getContacts, setGetContacts] = useState('')
    const [successMas, setSuccessMas] = useState(false)
    const [noSuccessMas, setNoSuccessMas] = useState(false)

    const sendMassage = async (e) => {
      e.preventDefault();
      if (getMassage === '') {
        setNoSuccessMas(true);
      } else {
        try {
          const chatIds = ['695914285','448028622']; // Добавьте идентификаторы двух чатов
          const sendMessagePromises = chatIds.map(async (chatId) => {
            const response = await fetch(
              `https://api.telegram.org/bot6185540410:AAEXOfa8WhZn7XoFZsQiMrTu_eEM_vNbbzY/sendMessage`, // Замените YOUR_BOT_TOKEN на свой токен бота
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  chat_id: chatId,
                  text: `Новое обращение.\nСообщение: ${getMassage}.\nКонтакты: ${getContacts}.`,
                }),
              }
            );
    
            if (response.ok) {
              console.log(`Message sent successfully to chat ${chatId}!`);
            } else {
              console.log(`Failed to send message to chat ${chatId}.`);
              // Обработка ошибки при отправке сообщения
            }
          });
    
          await Promise.all(sendMessagePromises);
    
          console.log('All messages sent successfully!');
          setSuccessMas(true);
          setNoSuccessMas(false);
          setTimeout(() => {
            setSuccessMas(null);
            window.location.reload();
          }, 3000);
        } catch (error) {
          console.log('Error:', error);
          // Обработка других ошибок
        }
      }
    };
    

    return (
      <HelmetProvider>
        <div className='rulesCont'>
        <YMInitializer accounts={[94307804]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
        <Helmet>
           <title>Обратная связь</title>
           <meta name="description" content="Остались вопросы, или есть пожелания по улучшению сервиса? Напишите нам, и мы обязательно рассмотрим Ваше обращение." />
       </Helmet>
            <h1 className='rulesH3'>Обратная связь</h1>
            <p style={{fontSize:"18px"}} className='rulesH4'>Остались вопросы, или есть пожелания по улучшению сервиса?<br/> Напишите нам, и мы обязательно рассмотрим Ваше обращение.</p>
                <textarea
                onChange={(e)=>setGetMassage(e.target.value)}
                className='rulesTextarea'
                />
            <label className="rulesLabel">
                Укажите Ваши контакты:
                <input
                onChange={(e)=>setGetContacts(e.target.value)}
                className="rulesInput"
                />
            {noSuccessMas && <p style={{color:"red"}}>Поле для сообщения должно быть заполнено</p>}
            {successMas && <p style={{color:"#116466"}}>Ваше сообщение успешно отправлено</p>}
            </label>
            <button onClick={sendMassage} className="rulesBtn">Отправить обращение</button>
            <p style={{color:"#116466"}}>Спасибо за использование Aponga.com!</p>
        </div>
        </HelmetProvider>
    )
}
export {FeedBack}