import { useState, useRef, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {doc, updateDoc, getFirestore, deleteDoc } from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import ButtonFormAdd from "../buttonFormAdd";
import '../css/FormAdd.css'
import close from './crossRed.png'
import firebaseConfig from '../firebaseConfig';
import { compressOne } from "../CompressedOne";
import { sendMassagePhoto } from "../SendTelegramPhoto";
import ModalAlert from "../modalAlert";

const RedFormSellTran = props => {
    //Видимость предупреждения об удалении
    const [visModdalAlert, setVisModalAlert] = useState(false)
    //Изменение высоты Текст ареа
    const [height, setHeight] = useState('90px');

      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const storage = getStorage(app);

   const closeWinRed = () => {
     props.onClick()
   }
  //Для уникальности изображения
  const uniquImg = (Date.now())

    //Адрес страницы
    const [getPath, setGetPath] = useState('')

    useEffect(()=>{
      //Выводим адрес страницы сайта, после главной
      const path = window.location.pathname;
      setGetPath(path)
    },[])

  //Не отправляем в телеграмм
  const [sendTelegram, setSendTelegram] = useState(false)

    //Изменяем кнопку input
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    //Загрузчик изображения
    const handleImageUpload = async (event) => {
      const file = event.target.files[0];
      const compressedImage = await compressOne(file);
      try {
        // Создаем ссылку на место загрузки файла в Firebase Storage
        const storageRef = ref(storage, `images/${uniquImg}`);
        
        // Загружаем файл в Firebase Storage
        await uploadBytes(storageRef, compressedImage);
        const getImageUrl = await getDownloadURL(storageRef);
        //При каждой загрузке изображения, ссылка будет добавлена в картеж
        
        const updatedImageUrl = [...newData.formItems.imageUrl, getImageUrl];
        const updatedFormItems = { ...newData.formItems, imageUrl: updatedImageUrl };
        const updatedData = { ...newData, formItems: updatedFormItems };

        setNewData(updatedData);

      } catch (error) {
        console.error('Ошибка при загрузке изображения:', error);
      }
    };

//Объект для редактирования объявления
const [newData, setNewData] = useState(props.add)

const handleCheckboxChange = () => {
  setNewData((prevNewData) => ({
    ...prevNewData,
    formItems: {
      ...prevNewData.formItems,
    clientNotShow: !prevNewData.formItems.clientNotShow
  }
}));
};
const handleCheckboxChangeModer = () => {
  setNewData((prevNewData) => ({
    ...prevNewData,
    formItems: {
      ...prevNewData.formItems,
    show: !prevNewData.formItems.show
  }
}));
};
//Изменение объекта newData
const handleChange = (e, name) => {
  const { value } = e.target;
  setNewData((prevData) => ({
    ...prevData,
    formItems: {
      ...prevData.formItems,
      [name]: value
    }
  }));
};

  //Удаление картинок
    const removeImg = (index) => {
      const updatedImageUrl = [...newData.formItems.imageUrl];
      updatedImageUrl.splice(index, 1);
  
      const updatedFormItems = { ...newData.formItems, imageUrl: updatedImageUrl };
      const updatedData = { ...newData, formItems: updatedFormItems };
  
      setNewData(updatedData);
    }

//Редактирование объявления
const updateDocument = async () => {
    try {
      const documentRef = doc(db, 'market', props.add.id);
      await updateDoc(documentRef, newData);
    } catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}

  //Сохранение нового объявления
  const upDoc =(docId)=>{
    updateDocument(docId).then((success) => {
      if (success) {
        sendMassagePhoto(newData.formItems, sendTelegram)
        // window.location.reload()
        //Перезагрузка страницы
      } else {
        if (getPath ==='/successenter'|| !newData.formItems.show) {
          window.location.reload()
          console.log('Ошибка при изменении документа');
        } else {
          sendMassagePhoto(newData.formItems, sendTelegram)
        }
      }
    });
    }

    // Удалить объявление
    const deleteDocument = async () => {
        try {
          const documentRef = doc(db, "users", props.add.id); 
          await deleteDoc(documentRef);
          window.location.reload();
        } catch (error) {
          console.error("Ошибка при удалении документа из базы данных", error);
        }
      };

      const closeModalAlert = () => {
        setVisModalAlert (false)
      }

    return(
      <>
      {visModdalAlert && <ModalAlert closeModalAlert={closeModalAlert} deleteDocument={deleteDocument}/>}
      <div style={{zIndex:"19", width:"100%", height:"100%", backgroundColor:"rgba(116, 116, 116, 0.9)", position:"fixed", top:'0', left:'0'}}></div>
      <div className='contRedFormAd'>
      <img className="closeImgRed" onClick={closeWinRed} src={close} alt='X' />
        <h3>Редактирование объявление</h3>
      <div style={{position:"relative"}}>
        <label>
          Марка транспортного средства:
          <input
          className='labelFormAd'
            name='brand'
            type="text"
            value={newData.formItems.brand}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>

      <div>
        <label >
          Модель:
          <input
          className='labelFormAd'
            name='model'
            type="text"
            value={newData.formItems.model}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>

      <div>
        <label>
          Год выпуска:
          <input
          className='labelFormAd'
          name='year'
            type='number'
            onWheel={(e) => e.target.blur()}
            value={newData.formItems.year}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>
      <div>
        <label>
          Пробег:
          <input
          className='labelFormAd'
          name='mileage'
            type='number'
            onWheel={(e) => e.target.blur()}
            value={newData.formItems.mileage}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>
      <div>
        <label>
          Объем двигателя:
          <input
          className='labelFormAd'
          name='engineSize'
            type='number'
            onWheel={(e) => e.target.blur()}
            value={newData.formItems.engineSize}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>
      <div>
        <label>
          Описание:
          <textarea
          className='labelFormAd'
          name='discription'
          style={{height:height}}
            type='text'
            value={newData.formItems.discription}
            onChange={(e) => {handleChange(e, e.target.name); setHeight(`${e.target.scrollHeight}px`)}}
          />
        </label>
      </div>
      <p>Цена (в BTH):</p>
      <div>
        <label>
          Цена:
          <input
          className='labelFormAd'
          name='price'
            type='number'
            onWheel={(e) => e.target.blur()}
            value={newData.formItems.price}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>
      <p>Контакты:</p>
      <div>
        <label>
          Whatsapp:
          <input
          className='labelFormAd'
          name='whatsapp'
            type='text'
            value={newData.formItems.whatsapp}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>
      <div>
        <label>
          Telegram:
          <input
          className='labelFormAd'
          name='telegram'
            type='text'
            value={newData.formItems.telegram}
            onChange={(e) => handleChange(e, e.target.name)}
          />
        </label>
      </div>
      {getPath ==='/moder' &&  
            <div>
            <label>
              Вывести в топ:
              <input
              className='labelFormAd'
              name='gold'
                type='number'
                onWheel={(e) => e.target.blur()}
                value={newData.formItems.gold}
                onChange={(e) => handleChange(e, e.target.name)}
              />
            </label>
          </div>
    }

      <button className='formBtnAd' onClick={handleButtonClick}>Добавить изображение</button>
      <input  style={{ display: 'none' }} ref={fileInputRef} type="file"  accept=".jpg, .jpeg, .png, .HEIF" onChange={handleImageUpload} placeholder='Файлы' />

      <div className="contMapImgAd" >
      {newData.formItems.imageUrl.length>0 && 
            newData.formItems.imageUrl.map((url, ind) =>
            <div className="divImgAd" key={parseFloat((ind)/7).toFixed(2)}>
                <img className='imgMapAd' src={url} alt="Изображение" />
                <ButtonFormAdd onClick={() => removeImg(ind)} ind={ind}>Убрать</ButtonFormAdd>
            </div>)}
            </div>
      {getPath ==='/successenter' ?
   <div className='redCheckBoxCont'>
      <label className="labelChackBox">
        Не показывать объявление:
      <input
      className='redCheckBox'
      type='checkbox'
      checked={newData.formItems.clientNotShow}
        onChange={handleCheckboxChange}
      />
      </label>
      </div>:

      <div>
      <div className='redCheckBoxCont'>
      <label className="labelChackBox">
        Разместить объявление:
      <input
      className='redCheckBoxModer'
      type='checkbox'
      checked={newData.formItems.show}
        onChange={handleCheckboxChangeModer}
      />
      </label>
      </div>
      <div className='redCheckBoxCont'>
      <label className="labelChackBox">
        Отправить в телеграм:
      <input
      className='redCheckBoxModer'
      type='checkbox'
      checked={sendTelegram}
        onChange={()=>setSendTelegram(!sendTelegram)}
      />
      </label>
      </div>
   </div>
   
      }
      <button  className='formBtnAd' onClick={() => upDoc()}>Сохранить изменения</button>
      <button className='formBtnAd' onClick={()=>{setVisModalAlert(true)}}>Удалить объявление</button>
        </div>
        </>
    )
}
export default RedFormSellTran