import  Resizer  from 'react-image-file-resizer';

export async function  compressOne (file){

    return new Promise((resolve) => {
        if (file.size > 90 * 1024) { // Проверка размера файла (в байтах)
          Resizer.imageFileResizer(
            file,
            1080, // оставляем прежнюю ширину изображения
            1080, // оставляем прежнюю высоту изображения
            'JPEG', // формат изображения
            30, // качество сжатия (от 0 до 100)
            0, // поворот изображения (в градусах)
            (blob) => {
              resolve(blob);
            },
            'blob' // выходной тип данных ('blob', 'base64' или 'file')
          );
          
        } else {
          resolve(file); // Возвращаем оригинальный файл без сжатия
        }
      });
    };