import { Link } from "react-router-dom"
import React, {useState } from 'react';
import '../css/ModerHead.css'

const ModerHead = props => {
//Артикль для поиска
  const [artSearch, setArtSearch] = useState(null)

  const [visBtn, setVisBtn] = useState (false)
  const [visBtn1, setVisBtn1] = useState (true)
  const [visBtn2, setVisBtn2] = useState (false)

  const filterModer = () => {
      props.filterModer()
      setVisBtn(true)
      setVisBtn1(false)
      setVisBtn2(false)
  }
  const allModer = () => {
      props.allModer()
      setVisBtn(false)
      setVisBtn1(true)
      setVisBtn2(false)
  }
  const filterArt = () => {
    props.filterArt(artSearch)
  }
  const getInfo = () => {
    props.getInfo()
    setVisBtn(false)
    setVisBtn1(false)
    setVisBtn2(true)
  }
    return (
        <div className='moderHead'>
            <div className="moderInpButtonCont">
            <div className='moderContInput'>
                <input 
                className='inputModer'
                placeholder='Поиск по артиклю по всем объявлениям'
                onChange={(e)=>{setArtSearch(e.target.value)}}/>
                <button onClick={filterArt} className='moderBtn' >Найти</button>
            </div>
             <Link className="moderLink" to="/">
                 <button className="moderHButton">Главная</button>
             </Link>
            </div>
            <div className="moderAllBtn">
                <button style={{marginRight:"1%"}} onClick={filterModer} className={visBtn ? "moderHButtonVis":"moderHButton"}>Объявления на модерации</button>
                <button style={{marginRight:"1%"}} onClick={allModer} className={visBtn1 ? "moderHButtonVis":"moderHButton"}>Все объявления</button>
                <button onClick={getInfo} className={visBtn2 ? "moderHButtonVis":"moderHButton"} >Информация</button>
            </div>
        </div>
    )
}
export default ModerHead