
const getSaveAds = (key) => {
    return new Promise((resolve, reject) => {
      if (window.Telegram) {
        const tg = window.Telegram.WebApp;
        tg.ready();
  
        // Получение данных из CloudStorage
        tg.CloudStorage.getItem(key, (error, result) => {
          if (error) {
            console.error('Ошибка при получении данных из CloudStorage:', error);
            reject(error);
          } else if (result) {
            console.log("Данные найдены:", result);
            resolve(JSON.parse(result));
          } else {
            console.log('Данные не найдены для ключа:', key);
            resolve(null);
          }
        });
      } else {
        reject(new Error('Telegram WebApp is not available'));
      }
    });
  };
  
  export default getSaveAds;
  