import back from '../image/leftBl.png'
import { useLocation, useNavigate } from 'react-router'
import '../css/Profile.css'
import '../css/ProfilePage.css'
import { useEffect, useState } from 'react';
import telega from '../image/telega.png'
import AllAdProfile from './AllAdProfile';
import saveAdLocalStorage from './saveAdLocalStorage';
import getSaveAds from './getSaveAds';
import deleteSaveAd from './deleteSaveAds';
// import testImg from '../image/testImg.JPG'

const ProfilePage = props => {
    const [filterData, setFilterData] = useState(null)
    const location = useLocation();
    const { userName, firstName, lastName } = location.state || {};
    const firstLetter = firstName ? firstName.charAt(0):"TG";
    const secondLetter = lastName? lastName.charAt(0):"";


    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, -80);
      },[])


    const stepBack = () => {
        navigate(-1)
    }

  //Подписка на пользователя
  const [haveUser, setHaveUser] = useState(false)

        //Сохраненные объявления
        useEffect(() => {
          const fetchData = async () => {
            const arr = await getSaveAds("arrSubUsers")
          //   console.log("Вытащенные данные из локал стораге!!!", arr);
            if (arr) {
              if (arr.includes(userName)) {
                setHaveUser(true)
              }
            }
          };
          if (userName) {
          fetchData();
          }
          //Чтобы при измененеии заново вызывался код
        }, [userName]);

  const subUser = ()=> {
    setHaveUser(true)
    const key = "arrSubUsers"
    saveAdLocalStorage(key, userName)
  }

  const unSubUser = () => {
    const key = "arrSubUsers"
    deleteSaveAd(key, userName)
    setHaveUser(false)
  }

//Мои объявления
useEffect(() => {
    const fetchData = () => {
      const allAds = props.allData;
      if (allAds && userName) {
        // console.log("Все данные из props:", allAds);
        // console.log("Имя пользователя:", userName);
        const filteredAds = allAds.filter(ad => ad.formItems.telegram === userName && ad.formItems.show === true);
        setFilterData(filteredAds);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [props]);

    return (
        <div className="contProfilePage">
            <div className="contHeadProfile">
                <img src={back} alt="<" className="profileBackImg" onClick={stepBack}/>
                <p style={{fontSize:"18px"}}>Коммерческий профиль</p>
            </div>
            <div className="headProfilePageTop" style={{backgroundImage:filterData? `url(${filterData[0].formItems.imageUrl[0]})`:"", backgroundSize:"cover" }}></div>
            <div className="headProfilePageBottom" >
                <div className="imageProfilePage">{firstLetter}{secondLetter}</div>
            </div>
                <p className="nameProfilePage">{firstName} {lastName}</p>
            <div className="contBtnProfilePage">
            {!haveUser ?
            <div className="btnAddProfilePage" onClick={subUser}>
                <p>Подписаться</p>
            </div>:
            <div className="btnAddProfilePage" style={{backgroundColor:"#cce3fd"}} onClick={unSubUser}>
                <p style={{color:"#1877F2"}}>Подписка</p>
             </div>
}
            <div className="btnTelega">
            <a href={`https://t.me/${userName}`} target='_blank' rel="noreferrer" style={{margin:'0',marginTop:"2px", color:"#116466"}}>
                <img src={telega} alt='>' className="imgTelega"/>
                </a>
            </div>
            </div>
            <p style={{marginLeft:"5%", fontSize:"22px", fontWeight:"bold", marginTop:"10px", marginBottom:"10px"}}>Объявления {firstName}</p>
            <AllAdProfile filterData={filterData}/>
        </div>
    )
}

export {ProfilePage}