import '../css/Rules.css'
import { Helmet, HelmetProvider} from 'react-helmet-async';
import { YMInitializer } from 'react-yandex-metrika';

const RulesApp = () => {
    return (
        <HelmetProvider>
        <div className='rulesCont'>
        <YMInitializer accounts={[94307804]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
        <Helmet>
           <title>Правила размещения объявлений</title>
           <meta name="description" content="Просим вас соблюдать эти правила при размещении объявлений на сайте. Мы стремимся создать безопасное и надежное сообщество для наших пользователей. В случае нарушения этих правил, мы оставляем за собой право удалить/редактировать объявление или применить другие меры, чтобы обеспечить качество и надежность нашей платформы." />
      </Helmet>
            <h3 className='rulesH3'>Правила</h3>
            <h1 style={{fontSize:"18px"}} className='rulesH4'>Правила размещения объявлений на Aponga.com</h1>
            <ol >
                <li style={{color:"#272727"}}>Язык объявлений: 
                    <ul>Все объявления должны быть размещены на русском языке, чтобы соответствовать нашей целевой аудитории русскоговорящих пользователей.</ul></li>
                    <br/>
                    <li style={{color:"#272727"}}>Категории объявлений: 
                    <ul>При размещении объявления выберите соответствующую категорию, чтобы облегчить пользователям поиск и фильтрацию. Пожалуйста, выбирайте наиболее подходящую категорию, чтобы ваше объявление было видно и легко находилось другими пользователями. Если вы не нашли соответствующей категории, то воспользуйтесь общей категорией – «Другое»</ul></li>
                    <br/>
                    <li style={{color:"#272727"}}>Полнота и точность информации: 
                    <ul>Предоставьте полную и точную информацию в объявлении. Укажите основные характеристики продукта, услуги или транспортного средства, такие как состояние, цена, местоположение, контактные данные и другие существенные детали. Более подробное и информативное объявление поможет привлечь заинтересованных пользователей.</ul></li>
                    <br/>
                    <li style={{color:"#272727"}}>Честность и ясность: 
                    <ul>Будьте честными и ясными в описании своих товаров, услуг и транспортных средств. Предоставляйте правдивую информацию и избегайте манипуляций, обмана или скрытых условий.</ul></li>
                    <br/>
                    <li style={{color:"#272727"}}>Фотографии: 
                    <ul>Добавляйте к объявлению качественные фотографии, которые наглядно демонстрируют продукт, услугу или транспортное средство, которое вы предлагаете. Хорошие фотографии могут привлечь больше внимания и повысить шансы на успешную продажу или аренду.</ul></li>
                    <br/>
                    <li style={{color:"#272727"}}>Запрещенный контент: 
                    <ul>Запрещено размещение объявлений, содержащих незаконный, оскорбительный, дискриминационный, порнографический или насильственный контент. Мы сохраняем право удалить любые объявления, нарушающие эти правила.</ul></li>
                    <br/>
                    <li style={{color:"#272727"}}>Повторное размещение: 
                    <ul>Просим вас не размещать одинаковые объявления повторно. Если ваше объявление осталось актуальным, вы можете обновить его, чтобы оно было в поиске более долгое время.</ul></li>
                    <br/>
                    <li style={{color:"#272727"}}>Безопасность: 
                    <ul>Все объявления проходят обязательную модерацию, но мы не можем вас полностью обезопасить от мошенничества.  При взаимодействии с другими пользователями, будьте осторожны и применяйте здравый смысл. Проводите сделки в безопасных местах и избегайте предоплат и подозрительных запросов. При возникновении подозрительных ситуаций, сообщите нам, чтобы мы могли принять соответствующие меры.</ul></li>
          </ol>
          <p style={{fontSize:"20px"}}>Просим вас соблюдать эти правила при размещении объявлений на Aponga.com. Мы стремимся создать безопасное и надежное сообщество для наших пользователей. В случае нарушения этих правил, мы оставляем за собой право удалить/редактировать объявление или применить другие меры, чтобы обеспечить качество и надежность нашей платформы.</p>
          <p style={{color:"#116466"}}>Спасибо за использование Aponga.com!</p>
        </div>
        </HelmetProvider>
    )
}
export {RulesApp}