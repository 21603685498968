import { useState } from 'react';
import '../css/AllAd.css'
import AllAd from './AllAd'; 
import SortTransport from './Sorts/SortTransport'
import SortRentProperty from './Sorts/SortRentProperty'
import SortSellProperty from './Sorts/SortSellProperty'
import SortServises from './Sorts/SortServises'
import SortStuff from './Sorts/SortStuff'
import { Helmet, HelmetProvider} from 'react-helmet-async';
import { YMInitializer } from 'react-yandex-metrika';
import SortSellTransport from './Sorts/SortSellTransport';
import back from '../image/back.png'
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from './LoadingScreen';


const FiltredData = props => {
//Сортировка
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortData, setSortData] = useState(props.filterData)

//Возврат на предыдущую страницу
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  
  const handleSortClick = (item) => {
    
    const sortedData = [...sortData];
    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.formItems[item] - b.formItems[item];
      } else {
        return b.formItems[item] - a.formItems[item];
      }
    });
  
    setSortData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  //Поиск
  //Когнда в поиске ничего не найдено
  const [empty, setEmpty] = useState(null)

  const getSearch = (search) => {
    if (search!=='') {
      const filtered = (props.filterData.filter(item => 
        Object.values(item.formItems).some((value) =>
        value.toString().toLowerCase().includes(search.toLowerCase())
        )
        )
      )
      setSortData(filtered)
      if (filtered.length === 0){setEmpty('По Вашему запросу ничего не найдено')}
          else {setEmpty(null)}
      }
    else {
      setEmpty(null)
      setSortData(props.filterData)
    }
  }

if (props.filterData) {
    return (
      <HelmetProvider>
      <div>  
      <YMInitializer accounts={[94307804]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
        <Helmet>
           <title>{props.head}</title>
           <meta name="description" content={props.decriptionFilter} />
      </Helmet>      
        <div className='contAllAdFilter'>
          {empty && <p style={{fontSize:"18.7px", fontWeight:"bold", marginLeft:"7%"}}>{empty}</p>}
          {props.category === 'sellTransport' && <SortSellTransport onClick={handleSortClick} getSearch={getSearch}/>}
          {props.category === 'rentTransport' && <SortTransport onClick={handleSortClick} getSearch={getSearch}/>}
          {props.category === 'sellProperty' && <SortSellProperty onClick={handleSortClick} getSearch={getSearch}/>}
          {props.category === 'rentProperty' && <SortRentProperty onClick={handleSortClick} getSearch={getSearch}/>}
          {props.category === 'servises' && <SortServises onClick={handleSortClick} getSearch={getSearch}/>}
          {props.category === 'stuff' && <SortStuff onClick={handleSortClick} getSearch={getSearch}/>}
          <div style={{display:"flex", flexDirection:"row", alignItems:"center", paddingTop:"1%"}}>
            <img onClick={goBack} className='imgBack' src={back} alt="-" />
             <h1 style={{marginBottom:"20px", marginTop:"20px", paddingRight:"7%", fontSize:"24px"}}>{props.head}</h1>
           </div>
          {sortData.length===0 && <p style={{marginLeft:"7%", color:"#116466", fontSize:"16px", fontWeight:"bold"}}>Твое объявление может стать первым!</p>}
          <AllAd filterData={sortData}/>
        </div>
        </div>
        </HelmetProvider>
    )
} else {
  return (
   <LoadingScreen/>
  )
}
}

export {FiltredData}