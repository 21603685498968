import './CreateAd.css'
import { useNavigate } from 'react-router'
import FormAddRentTran from '../FormAddLK/formAddRentTran'

import user from './user.png'

const CreateRentBike = props => {
    const navigate = useNavigate()
    const back = () =>{
        navigate(-1)
    }
//клик по кнопке Главная, переводим на главную
const gooHome = () => {
        navigate('/')
      }

    return (
        <div сlassName="contCreateAd">
            <div className="headerCreateAd" >
                <p className="linkCreateAd" onClick={back}>Отмена</p>
                <p className="textHeaderCreateAd">Новое объявление</p>
                <p className="linkCreateAd" onClick={gooHome}>Главная</p>
            </div>
            <div className="contNameAd">
                <img src={user} alt="U" className="imgProfileAd"/>
                {props.userData? <p className="textNameAd">{props.userData.first_name} {props.userData.last_name}</p>: <p className="textNameAd">Пользователь Телеграм</p>}
            </div>
            <FormAddRentTran userData={props.userData}/>
        </div>
    )
}

export {CreateRentBike}