import '../css/ListSaveProfiles.css'
import leftBl from '../image/leftBl.png'
import dots from '../image/dots.png'
// import imgTest from '../image/testImg.JPG'
import { useNavigate, useLocation } from 'react-router'
import getSaveAds from './getSaveAds';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import user from '../image/user.png'

const ListSaveProfiles = props => {
    const moment = require('moment');
    require('moment/locale/ru');

    const navigate = useNavigate()
    const location = useLocation()
    const [listData1, setListData1] = useState([])

    const stepBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        const fetchData = async () => {
          const allAds = props.allData;
          const arr = await getSaveAds("arrSubUsers")
          if (arr && allAds) {
            console.log("Вытащенные данные из локал стораге", arr);
            const filteredAds = allAds.filter(ad => arr.includes(ad.formItems.telegram));
            const group = (groupByTelegram(filteredAds))
            const groupArr = Object.values(group).map(value => {
                return value;
              });
              setListData1(groupArr)
          }
        };
    
        fetchData();
      }, [props]);

      const groupByTelegram = (listData) => {
        return listData.reduce((acc, item) => {
          const telegramKey = item.formItems.telegram;
          if (!acc[telegramKey]) {
            acc[telegramKey] = [];
          }
          acc[telegramKey].push(item);
          return acc;
        }, {});
      };


      const goToProfile = (userName, firstName, lastName) => {
        navigate("/profilepage", { state: { userName, firstName, lastName, from: location } })
      }


    return (
        <div>
            <div className="contHeadSaveProfiles" style={{marginBottom:"15px"}}>
                <img src={leftBl} alt="<" className="saveProfileBackImg" onClick={stepBack}/>
                <p style={{fontSize:"18px", fontWeight:"600"}}>Подписки</p>
            </div>
            <div className="allMyAdsSave">
                {listData1.length > 0 ? listData1.map((ad, id) => (
                    <div className="eachmyAdSave" key={id} onClick={()=>goToProfile(ad[0].formItems.telegram, ad[0].formItems.firstName, ad[0].formItems.lastName)}>
                    <div className="imageProfilePageSave">{ad[0].formItems.firstName ? ad[0].formItems.firstName.charAt(0):"TG"}{ad[0].formItems.lastName ? ad[0].formItems.lastName.charAt(0):""}</div>
                    <div className="contTextMyAdsSave">
                        <p className="text1MyAdSave">{ad[0].formItems.firstName} {ad[0].formItems.lastName}</p>
                        <p className="text3MyAdSave">{ad.length} объявлений</p>
                    </div>
                    <img src={dots} className="imgMyAdsRightSave" alt="..."/>
                    </div>
                )
            )
                    :
                    <p style={{marginLeft:"3%", fontWeight:"bold"}}>Нет подписок на пользователей</p>

            }
            </div>
        </div>
    )
}

export {ListSaveProfiles}