import '../css/AllAd.css'
import { Link } from 'react-router-dom';
import CardsRentTransport from './Cards/CardsRentTransport';
import CardsSellTransport from './Cards/CardsSellTransport'
import CardsStuff from './Cards/CardsStuff';
import CardsServises from './Cards/CardsServises'
import CardsSellProperty from './Cards/CardsSellProperty';
import CardsRentProperty from './Cards/CardsRentProperty'
import { useEffect, useState } from 'react';
import {getFirestore, doc, getDoc, updateDoc} from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../js/firebaseConfig';
// import eye from '../image/eye.png'
import { LoadingScreen } from './LoadingScreen';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const AllAd = props => {

const [arrArts, seArrArts] = useState([])

useEffect(()=>{
  seArrArts(JSON.parse(localStorage.getItem('artsArray')) || [])

  // console.log("Данне из всех объявлений", props)
},[props])

//Проверка на просмотр карточек, если просмотрено, надпись просмотрено
const getShown = (number, id) =>{
  if (arrArts.includes(number)) {
  } else {
  postShow(id)
  const updatedItems = [...arrArts, number]
  localStorage.setItem('artsArray', JSON.stringify(updatedItems));
  seArrArts(JSON.parse(localStorage.getItem('artsArray')) || [])
}
}
//Добавляем просмотр в базу данных
const postShow = async (id) => {
    try {
      const documentRef = doc(db, 'market', id);
      const documentSnapshot = await getDoc(documentRef);
      if (documentSnapshot.exists()) {
        const currentData = documentSnapshot.data();
        const currentCountShow = currentData.countShow;
        const newCountShow = currentCountShow + 1;

    await updateDoc(documentRef, {countShow: newCountShow});
     } else {
        return false
      }
    }
    catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}


if (props.filterData) {
    return (
      <div > 
        <div className='contAllAd'>
            <div className="cards" >
                {props.filterData && props.filterData.map((ad, index) => 
                <div key={index} style={{position:"relative"}}>
                  {/* {arrArts.includes(ad.formItems.art) && 
                  <div className="shownAllAdd">
                    <p >Просмотрено</p>
                  </div>} */}
                  {/* <div style={{position:"absolute", right:"10px", top:"10px", paddingTop:"1px", paddingBottom:"1px", zIndex:"9", backgroundColor:"white", opacity:"0.7", display:"flex", flexDirection:"row", alignItems:"center", paddingLeft:"3px", paddingRight:"3px", borderRadius:"3px"}}>
                   <img src={eye} alt='o' style={{width:"15px", height:"15px", marginRight:"5px"}}/>
                  <p style={{margin:"0", fontSize:"10px"}}>{ad.countShow}</p>
                  </div> */}
                  <Link  onClick={()=>{getShown(ad.formItems.art, ad.id); (window.ym(94307804,'reachGoal','cardClick'))}} className='linkAllAd' to={`/ad${ad.formItems.art}`}>
                  {ad.formItems.category==='rentMoto' ? <CardsRentTransport ad={ad.formItems} />: 
                     ad.formItems.category==='sellTransport' ? <CardsSellTransport ad={ad.formItems}/>:
                     ad.formItems.category==='servises' ? <CardsServises ad={ad.formItems}/>:
                     ad.formItems.category==='stuff' ? <CardsStuff ad={ad.formItems} />:
                     ad.formItems.category==='rentProperty' ? <CardsRentProperty ad={ad.formItems} />:
                     ad.formItems.category==='sellProperty' ?<CardsSellProperty ad={ad.formItems} />:
                     <div/>}
                  </Link>
                </div>)}
                
            </div>
        </div>
        </div>
    )
} else {
  return (
    <LoadingScreen/>
  )
}
}

export default AllAd