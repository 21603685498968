import React, { memo } from 'react';
import close from '../image/crossWhite.png'

const ButtonFormAdd  = memo(({onClick }) => {


  const handleClick = () => {
    onClick()
  };

  return (
    <div onClick={handleClick} style={{width:"22px", height:"22px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", backgroundColor:"rgba(0, 0, 0, 0.7)", borderRadius:"50%" }}>
      <img src={close} style={{width:"13px", height:"13px"}} alt="X"/>
    </div>
);
  })

export default ButtonFormAdd;