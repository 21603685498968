import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Sort.css'
import up from'./up.png'
import down from './down.png'
import sortAnsw from'./sort.png'


const SortSellProperty = ({onClick, getSearch}) => {
    
const handleSearch = () => {
    getSearch(search)
}

const [visImgPrice, setVisImgPrice] = useState(true)
const [visImgDate, setVisImgDate] = useState(true)

const images = [down, up]
const [currentImageIndex, setCurrentImageIndex] = useState(0);

const clickPrice = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setVisImgPrice(false)
    setVisImgDate(true)
    onClick('price')


}
const clickDate = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setVisImgDate(false)
    setVisImgPrice(true)
    onClick('dateAd')
}


    const [search, setSearch] = useState('')
 
    return (
        <div className="contSort">
            <div className="sortinpButtonCont">
            <div className='sortContInput'>
                <input 
                className='sortInput'
                placeholder='Модель или марка'
                value={search}
                onChange={(e)=>{setSearch(e.target.value)}}
                />
                <button onClick={handleSearch} className='sortInputButton'>Найти</button>
             </div>
            <Link to='/' className='sortLink'>
             <button className="sortBtnAds">Все категории</button>
             </Link>
             </div>
             <div className='sortAllBtn'>
                <div style={{marginLeft:"0px"}} className='sortBtn' onClick={clickPrice} >
                    <p className='sortBtnText'>По цене</p>
                    <img className='sortBtnImg' src={visImgPrice ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div>
                <div className='sortBtn'onClick={clickDate} >
                    <p className='sortBtnText'>По дате</p>
                    <img className='sortBtnImg' src={visImgDate ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div>
                {/* <div className='sortBtn' onClick={clickRooms}>
                    <p className='sortBtnText'>Кол. комнат</p>
                    <img className='sortBtnImg' src={visImgRooms ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div> */}
             </div>
        </div>
    )
}

export default SortSellProperty