
import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {collection, getFirestore,getDocs} from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import React, {useState, useEffect } from 'react';

import { HomePageNew } from './js/HomePageNew';
import {Moder} from './js/Moder'
import { FiltredData } from './js/FiltredData';
import { AdRentMoto } from './js/AdForm/AdRentMoto';
import { AdRentAuto } from './js/AdForm/AdRentAuto';
import { AdSellAuto } from './js/AdForm/AdSellAuto';
import { AdSellMoto } from './js/AdForm/AdSellMoto';
import { AdServises } from './js/AdForm/AdServises';
import { AdStuff } from './js/AdForm/AdStuff';
import { AdRentProperty } from './js/AdForm/AdRentProperty';
import { AdSellProperty } from './js/AdForm/AdSellProperty';
import { AboutUs } from './js/AboutUs';
import { RulesApp} from './js/RulesApp'
import { FeedBack } from './js/FeedBack';
import { Terms } from './js/Terms'
import { NotFound} from './js/notFound'
import { Search } from './js/Search';
import { Profile } from './js/Profile';
import { CreateRentBike } from './js/CreateAdd/CreateRentBike';
import { ListSaveAds } from './js/ListSaveAds';
import  { ListSaveProfiles} from './js/ListSaveProfiles'
import { ProfilePage } from './js/ProfilePage';
import { RedAdCommon } from './js/RedFormNew/RedAdCommon'

import firebaseConfig from './js/firebaseConfig';
import { LoadingScreen } from './js/LoadingScreen';



const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {

  //Сделано для того чтобы успело все програузится, в противном случае отображается сначала нотФаунд
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const delay = setTimeout(() => {
      setIsLoading(true);
    }, 3000);
  
    return () => {
      clearTimeout(delay);
    };
  
  },[])

  //Данные пользователя телеграмм
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;
      const gl = window.Telegram
      tg.ready();
      tg.setHeaderColor('#cce3fd')
      tg.setBackgroundColor('#ffffff')
      tg.disableVerticalSwipes()
      console.log('Telegram WebApp initialized:', tg);
      console.log('Глобальный ТГ:', gl);
      // console.log('Проверяем, возможно данные чата:', tg.initData);
      // console.log("Данные пользователя", tg.initDataUnsafe.user)
      // console.log(tg.initDataUnsafe.user)
      setUserData(tg.initDataUnsafe.user)
      // tg.SettingsButton.show();
      tg.onEvent('settingsButtonClicked', () => {
        console.log('Settings button was clicked');
      });

    }
  }, []);


  const [allData, setAllData] = useState(null)

  const [filterRentAuto, setFilterRentAuto] = useState(null)
  const [filterRentMoto, setFilterRentMoto] = useState(null)
  const [filterSellMoto, setFilterSellMoto] = useState(null)
  const [filterSellAuto, setFilterSellAuto] = useState(null)
  const [filterRentProperty, setFilterRentProperty] = useState(null)
  const [filterSellProperty, setFilterSellProperty] = useState(null)
  const [filterStuff, setFilterStuff] = useState(null)
  const [filterServises, setFilterServises] = useState(null)

  useEffect(()=>{  
    // Функция для получения данных пользователя по его id
    const getAllData = async () => {
      try {
        // console.log("Попытка извлечения")
        //Извлекаем данные с условиями (по старому как в апонге)
        // const usersCollectionRef = collection(db, 'market');
        // const q = query(usersCollectionRef, where('formItems.show', '==', true ), where('formItems.clientNotShow', '==', false ));
        // const querySnapshot = await getDocs(q);
        const usersCollectionRef = collection(db, 'market');
        const querySnapshot = await getDocs(usersCollectionRef);

        if (querySnapshot.empty) {
          console.log("Нет данных")
          return null;
        }
        const getAllData = [];
        querySnapshot.forEach((doc) => {
            getAllData.push({ id: doc.id, ...doc.data() });
        });
        // console.log("Вроде есть данные")
        //Сортировка по параметру Gold
        console.log(getAllData)
        setAllData(getAllData)
        //Фильтруем для страниц с категориями
        const filtered = (getAllData.filter(item => item.formItems.underCategory === 'rentAuto'))
        setFilterRentAuto(filtered)
        
        //Пока только для аренды байков, протестить надо или нет
        const filtered1 = (getAllData.filter(item => item.formItems.underCategory === 'rentMoto'))
        const sortedData = [...filtered1];
        sortedData.sort((a, b) => {
            return  b.formItems.gold - a.formItems.gold;
        });
        setFilterRentMoto(sortedData)

        const filtered2 = (getAllData.filter(item => item.formItems.underCategory === 'sellAuto'))
        setFilterSellAuto(filtered2)
        const filtered3 = (getAllData.filter(item => item.formItems.underCategory === 'sellMoto'))
        setFilterSellMoto(filtered3)
        const filtered4 = (getAllData.filter(item => item.formItems.category === 'rentProperty'))
        setFilterRentProperty(filtered4)
        const filtered5 = (getAllData.filter(item => item.formItems.category === 'sellProperty'))
        setFilterSellProperty(filtered5)
        const filtered6 = (getAllData.filter(item => item.formItems.category === 'stuff'))
        setFilterStuff(filtered6)
        const filtered7 = (getAllData.filter(item => item.formItems.category === 'servises'))
        setFilterServises(filtered7)
        // console.log(getAllData)
        return getAllData;
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
        return null;
      }

    };

    //Функция отображения объявлений
    const allAdd = () => {
        getAllData().then((getAllData) => {
      if (getAllData) {
        setAllData(getAllData)
      }
    });
    }
    allAdd()
    },[])


  const decriptionFilter = {
    rentAuto:"Ищете аренду автомобиля на Пхукете? Наш сайт предлагает широкий выбор автомобилей для аренды на привлекательных условиях. Откройте для себя комфорт и свободу передвижения по острову в собственном темпе. У нас вы найдете разнообразные модели автомобилей, доступные по выгодным ценам. Бронируйте удобный автомобиль прямо сейчас и наслаждайтесь своим отдыхом на Пхукете с максимальным комфортом и независимостью.",
    rentMoto:"Хотите арендовать байк на Пхукете? Наш сайт предлагает широкий выбор байков для аренды по выгодным условиям. Почувствуйте свободу передвижения по острову на двух колесах. У нас вы найдете разнообразные модели байков, от спортивных до городских, доступные по привлекательным ценам. Забронируйте удобный байк уже сейчас и наслаждайтесь своим отдыхом на Пхукете, наслаждающись путешествием без ограничений.",
    sellAuto:"Ищете автомобиль для покупки на Пхукете? Наш сайт предлагает разнообразные модели автомобилей в продаже по привлекательным ценам. Мы предлагаем широкий выбор автомобилей различных марок и моделей, в отличном состоянии и готовых к использованию. Независимо от ваших предпочтений и бюджета, у нас вы найдете подходящий вариант. Приобретая автомобиль у нас, вы получаете возможность насладиться свободой передвижения по острову и окрестностям в удобное для вас время. Не упустите возможность стать владельцем автомобиля на Пхукете и выберите свой идеальный автомобиль уже сегодня.",
    sellMoto:"Хотите купить байк на Пхукете? Наш сайт предлагает широкий выбор байков для продажи по привлекательным ценам. У нас вы найдете разнообразные модели байков, от спортивных до городских, в отличном состоянии и готовые к использованию. Приобретая у нас, вы получаете возможность насладиться свободой передвижения по острову на собственном байке. Выбирайте подходящий вам байк и совершите покупку прямо сейчас, чтобы наслаждаться комфортными поездками по Пхукету и окрестностям.",
    rentProperty:"Ищете недвижимость для аренды на Пхукете? Наш сайт предлагает широкий выбор недвижимости в аренду с привлекательными условиями. У нас вы найдете виллы, квартиры, дома и апартаменты различных размеров и стилей, чтобы соответствовать вашим потребностям и предпочтениям. Насладитесь роскошью и комфортом во время пребывания на Пхукете.", 
    sellProperty:"Ищете недвижимость для покупки на Пхукете? Наш сайт предлагает широкий выбор недвижимости по привлекательным ценам. Мы предлагаем различные типы недвижимости, включая виллы, аппартаменты, дома и земельные участки, на которых можно построить своё идеальное жильё. Пхукет известен своими красивыми пляжами и живописными видами, и приобретение недвижимости на этом острове может стать вашим уголком рая. Не упустите возможность стать владельцем недвижимости на Пхукете и создайте свой уютный уголок в этом райском месте.",
    stuff:"Ищете место для продажи товаров на Пхукете? Наш сайт предлагает возможность разместить ваше предложение о продаже товаров на этом прекрасном острове. Мы предоставляем платформу для различных видов товаров, включая одежду, электронику, аксессуары, сувениры и многое другое. Размещение объявления у нас позволит вам привлечь внимание широкой аудитории, включая местных жителей и туристов, которые посещают Пхукет. Это отличная возможность продвигать и продавать свои товары в этом популярном туристическом месте. Зарегистрируйтесь на нашем сайте и начните продавать ваши товары на Пхукете уже сегодня.",
    servises:"Ищете услуги на Пхукете? Наш сайт предлагает широкий спектр услуг, доступных на этом острове. Мы предоставляем возможность разместить объявление о вашей услуге и привлечь внимание к вашему предложению. Независимо от того, нужны ли вам услуги экскурсий, аренды транспорта, массажа, ресторанов, отелей, перевода или чего-либо еще, вы можете найти и предложить их на нашей платформе. Мы помогаем связывать провайдеров услуг с клиентами, предлагая возможность эффективной рекламы и продвижения вашей деятельности на Пхукете. Зарегистрируйтесь на нашем сайте и начните предоставлять свои услуги на Пхукете уже сегодня."
  }
  
  return (

    <Router >
      <div className="cont">
      <Routes>
        <Route path="/" element={<HomePageNew data={allData}/>} />
        <Route path="moder" element={<Moder />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="rules" element={<RulesApp />} />
        <Route path="feedback" element={<FeedBack />} />
        <Route path="terms" element={<Terms />} />
        <Route path="search" element={<Search allData={allData}/>} />
        <Route path="profile" element={<Profile userData={userData} allData={allData}/>} />
        <Route path="addrentbike" element={<CreateRentBike userData={userData}/>} />
        <Route path="saveads" element={<ListSaveAds allData={allData}/>} />
        <Route path="savepropfiles" element={<ListSaveProfiles allData={allData}/>} />
        <Route path="profilepage" element={<ProfilePage allData={allData}/>} />

        <Route path="redad" element={<RedAdCommon/>} />

        {filterRentAuto && <Route path="allautorent" element={<FiltredData filterData={filterRentAuto} head='Аренда автомобилей на Пхукете' category='rentTransport' decriptionFilter={decriptionFilter.rentAuto}/>} />}
        {filterRentMoto && <Route path="allmotorent" element={<FiltredData filterData={filterRentMoto} head='Аренда байков на Пхукете' category='rentTransport'/>} />}
        {filterSellMoto && <Route path="allmotosell" element={<FiltredData filterData={filterSellMoto} head='Продажа байков на Пхукете' category='sellTransport'/>} />}
        {filterSellAuto && <Route path="allautosell" element={<FiltredData filterData={filterSellAuto} head='Продажа автомобилей на Пхукете:' category='sellTransport'/>} />}
        {filterSellProperty && <Route path="allpropertysell" element={<FiltredData filterData={filterSellProperty} head='Продажа недвижимости на Пхукете' category='sellProperty'/>} />}
        {filterRentProperty && <Route path="allpropertyrent" element={<FiltredData filterData={filterRentProperty} head='Аренда недвижимости на Пхукете' category='rentProperty'/>} />}
        {filterStuff && <Route path="allstuff" element={<FiltredData filterData={filterStuff} head='Продажа товаров на Пхукете' category='stuff'/>} />}
        {filterServises && <Route path="allservises" element={<FiltredData filterData={filterServises} head='Услуги на Пхукете' category='servises'/>} />}

        {allData && allData.map((ad, ind)=>
        <Route key={ind} path={`/ad${ad.formItems.art}`} element= {
        ad.formItems.underCategory==='rentMoto' ? <AdRentMoto ad={ad.formItems} userData={userData}/>:
        ad.formItems.underCategory==='rentAuto' ? <AdRentAuto ad={ad.formItems}/>:
        ad.formItems.underCategory==='sellAuto' ? <AdSellAuto ad={ad.formItems}/>:
        ad.formItems.underCategory==='sellMoto' ? <AdSellMoto ad={ad.formItems}/>:
        ad.formItems.category==='servises' ? <AdServises ad={ad.formItems}/>:
        ad.formItems.category==='stuff' ? <AdStuff ad={ad.formItems}/>:
        ad.formItems.category==='rentProperty' ? <AdRentProperty ad={ad.formItems}/>:
        ad.formItems.category==='sellProperty' ? <AdSellProperty ad={ad.formItems}/>:
        <div/>}/>)}
        {isLoading ? <Route path="*" element={<NotFound />} />: <Route path="*" element={<LoadingScreen/>}/>}
      </Routes>
      </div>
    </Router>

  );
}

export default App;
