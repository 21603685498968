import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Sort.css'
import up from'./up.png'
import down from './down.png'
import sortAnsw from'./sort.png'


const SortSellTransport = ({onClick, getSearch}) => {

//Получаем ширину экрана
// const [windowWidth, setWindowWidth] = useState(window.innerWidth);

const handleSearch = () => {
    getSearch(search)
}


const [visImgPrice, setVisImgPrice] = useState(true)
const [visImgYear, setVisImgYear] = useState(true)
const [visImgMileage, setVisImgMileage] = useState(true)
const [visImgSize, setVisImgSize] = useState(true)
const [visImgDate, setVisImgDate] = useState(true)

const images = [down, up]
const [currentImageIndex, setCurrentImageIndex] = useState(0);

const clickPrice = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setVisImgPrice(false)
    setVisImgYear(true)
    setVisImgMileage(true)
    setVisImgSize(true)
    setVisImgDate(true)
    onClick("price")
    
}
const clickYear = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setVisImgYear(false)
    setVisImgPrice(true)
    setVisImgMileage(true) 
    setVisImgSize(true)
    setVisImgDate(true)
    onClick("year")
}
const clickMileage = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setVisImgMileage(false) 
    setVisImgYear(true)
    setVisImgPrice(true)
    setVisImgSize(true)
    setVisImgDate(true)
    onClick("mileage")
}
const clickSize = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setVisImgSize(false)
    setVisImgMileage(true) 
    setVisImgYear(true)
    setVisImgPrice(true)
    setVisImgDate(true)
    onClick("engineSize")
}
const clickDate = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setVisImgSize(true)
    setVisImgMileage(true) 
    setVisImgYear(true)
    setVisImgPrice(true)
    setVisImgDate(false)
    onClick("dateAd")
}

    const [search, setSearch] = useState('')
 
    return (
        <div className="contSort">
            <div className="sortinpButtonCont">
            <div className='sortContInput'>
                <input 
                className='sortInput'
                placeholder='Модель или марка'
                value={search}
                onChange={(e)=>{setSearch(e.target.value)}}
                />
                <button onClick={handleSearch} className='sortInputButton'>Найти</button>
             </div>
            <Link to='/' className='sortLink'>
             <button className="sortBtnAds">Все категории</button>
             </Link>
             </div>
             <div className='sortAllBtn'>
                <div style={{marginLeft:"0px"}} className='sortBtn' onClick={clickPrice} >
                    <p className='sortBtnText'>По цене</p>
                    <img className='sortBtnImg' src={visImgPrice ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div>
                <div className='sortBtn'onClick={clickYear} >
                    <p className='sortBtnText'>По году</p>
                    <img className='sortBtnImg' src={visImgYear ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div>
                <div className='sortBtn' onClick={clickMileage}>
                    <p className='sortBtnText'>По пробегу</p>
                    <img className='sortBtnImg' src={visImgMileage ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div>
                <div className='sortBtn' onClick={clickSize}>
                    <p className='sortBtnText'>По объему</p>
                    <img className='sortBtnImg' src={visImgSize ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div>
                <div className='sortBtn' onClick={clickDate}>
                    <p className='sortBtnText'>По дате</p>
                    <img className='sortBtnImg' src={visImgDate ? sortAnsw : images[currentImageIndex]} alt='|'></img>
                </div>
             </div>
        </div>
    )
}

export default SortSellTransport