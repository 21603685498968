import '../css/CreateAddModal.css'
import '../css/Search.css'
import { useRef, useState } from 'react'
import rentAuto from '../image/rentAuto1.png'
import rentBike from '../image/rentBike1.png'
import sellAuto from '../image/sellAuto1.png'
import sellBike from '../image/sellBike1.png'
import rentHouse from '../image/rentHouse1.png'
import sellHouse from '../image/sellHouse1.png'
import gods from '../image/gods1.png'
import service from '../image/serice1.png'
import { Link } from 'react-router-dom'



const CreateAddModal = props => {

const [animeName, setAnimeName] = useState("slideUp")

const blockRef = useRef(null);
let startY;

const close = () => {
    props.getModal()
}

const handleTouchStart = (event) => {
    startY = event.touches[0].clientY;
  }

const  handleTouchMove = (event) => {
    const deltaY = event.touches[0].clientY - startY;
    if (deltaY > 50) { // Adjust the threshold as needed
    //   console.log('Swiped down!');
      // You can perform any action here
      play()
    }
  }

//Чтобы при клике по всплывающему окну оно не закрывалось
const handleChildClick = (event) => {
    event.stopPropagation();
  };

const play = () => {
    setAnimeName("slideDown")
    setTimeout(()=>{close()},600) 
}

    return (
        <div className="contAdModal" onClick={play}>
            <div className="windowAdModal" style={{animationName:animeName}} onClick={handleChildClick} ref={blockRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                {/* <div style={{width:"100%", height:"0%", position:"fixed", top:"0"}} ></div> */}
                <div style={{width:"50px", height:"4px", borderRadius:"2px", backgroundColor:"rgb(206, 206, 206)", margin:"auto", marginTop:"8px"}}></div>
                <p className="headWindowModal">Создать новое объявление</p>
                <div className="eachList">
                <img src={rentAuto} alt="o" className="imgList" />
                <p className="textList">Аренда автомобилей</p>
            </div>
            <Link to="/addrentbike" style={{textDecoration:"none", color:"black"}}>
            <div className="eachList">
                <img src={rentBike} alt="o" className="imgList" />
                <p className="textList">Аренда байков</p>
            </div>
            </Link>
            <div className="eachList">
                <img src={sellAuto} alt="o" className="imgList" />
                <p className="textList">Продажа автомобилей</p>
            </div>
            <div className="eachList">
                <img src={sellBike} alt="o" className="imgList" />
                <p className="textList">Продажа байков</p>
            </div>
            <div className="eachList">
                <img src={rentHouse} alt="o" className="imgList" />
                <p className="textList">Аренда Недвижимости</p>
            </div>
            <div className="eachList">
                <img src={sellHouse} alt="o" className="imgList" />
                <p className="textList">Продажа недвижимости</p>
            </div>
            <div className="eachList">
                <img src={gods} alt="o" className="imgList" />
                <p className="textList">Товары</p>
            </div>
            <div className="eachList" style={{borderBottom:"1px solid rgb(194, 194, 194)", paddingBottom:"5px"}}>
                <img src={service} alt="o" className="imgList" />
                <p className="textList">Услуги</p>
            </div>
            </div>
        </div>
    )
}

export default CreateAddModal