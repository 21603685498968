

const InfoModer = () => {


    return (
        <div >
            <p>Info</p>
        </div>
    )
}
 export default InfoModer