import '../css/LoadingScreen.css'
import logo from '../image/logoLoad.png'

const LoadingScreen = () => {
    return (
        <div className='contLoading'>
            <img src={logo} alt='Loading...'/>
        </div>
    )
}

export {LoadingScreen}