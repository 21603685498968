import { useState, useEffect } from 'react';
import './AdFormNew.css'
// import whatsapp from'./whatsapp.png'
// import telegram from'./telegram.png'
import { useLocation, useNavigate } from 'react-router'
import back from './closeBl.png'
import lupa from './lupa.png'
import telegram from './telegram.png'
import telega from './telega.png'
// import share from './share.png'
import favorite from './favorite.png'
import favoriteBlue from './favoriteBlue.png'
import alarm from './alarm.png'
import user from './user.png'
import copyBl from './copyBl1.png'
import { sendMassage } from "../sendTelegram";
// import { AdSellProperty } from './AdSellProperty';
import saveAdLocalStorage from '../saveAdLocalStorage';
import getSaveAds from '../getSaveAds';
import deleteSaveAd from '../deleteSaveAds';


const AdRentMoto = (ad, userData) => {

  const [mesAd, setMesAd] = useState("")
  const [isHave, setIsHave] = useState(false)
  const [visNot, setVisNot] = useState(false)
  const [visCopy, setVisCopy] = useState(false)
  const [visSave, setVisSave] = useState(false)

  const navigate = useNavigate();
  const location = useLocation()

  const isOpen = location.state?.isOpen ?? false;

      //Сохраненные объявления
      useEffect(() => {

          const fetchData = async () => {
            const arr = await getSaveAds("arrArts1")
          //   console.log("Вытащенные данные из локал стораге!!!", arr);
            if (arr) {
              console.log("Вытащенные данные из локал стораге в APP", arr);
              if (arr.includes(ad.ad.art)) {
                setIsHave(true)
              }
            }
          };
      
          fetchData();
          //Чтобы при измененеии заново вызывался код
        }, [visSave]);

  useEffect(()=>{
    if (ad) {
      setMesAd(`${ad.ad.firstName}, ${ad.ad.brand} ${ad.ad.model} доступен?`)
    }
  },[ad])

  useEffect(() => {
    window.scrollTo(0, -80);
  },[])

  //Сохраняем объявление
  const saveListAds = ()=> {
    setIsHave(true)
    console.log("Смотрим и ищем ид", ad)
    const key = "arrArts1"
    saveAdLocalStorage(key, ad.ad.art)
    setVisSave(true)
    setTimeout(()=>{
      setVisSave(false)
    },1500)
  }

  //Подписка на пользователя
  const [haveUser, setHaveUser] = useState(false)

        //Сохраненные объявления
        useEffect(() => {
          const fetchData = async () => {
            const arr = await getSaveAds("arrSubUsers")
          //   console.log("Вытащенные данные из локал стораге!!!", arr);
            if (arr) {
              if (arr.includes(ad.ad.telegram)) {
                setHaveUser(true)
              }
            }
          };
      
          fetchData();
          //Чтобы при измененеии заново вызывался код
        }, [visSave]);

  const subUser = ()=> {
    setHaveUser(true)
    const key = "arrSubUsers"
    saveAdLocalStorage(key, ad.ad.telegram)
  }

  const unSubUser = () => {
    const key = "arrSubUsers"
    deleteSaveAd(key, ad.ad.telegram)
    setHaveUser(false)
  }

  //Удаляем сохраненное объявление
  const delSaveAd = () => {
    const key = "arrArts1"
    deleteSaveAd(key, ad.ad.art)
    setIsHave(false)
  }

  const handleSave = () => {
    const text = `https://t.me/TG_marketplaceBot/TGmarketplace?startapp=ad${ad.ad.art}`
    navigator.clipboard.writeText(text).then(() => {
      setVisCopy(true)
      setTimeout(()=>{
        setVisCopy(false)
      },1500)
    }).catch(err => {
      console.error('Ошибка при копировании:', err);
    });
  };
  
  //Подписка на оповещения ПОТОМ
  const notific = () => {
    setVisNot(true)
    setTimeout(()=>{
      setVisNot(false)
    },1500)
  }
  
    // const [visContact, setVisContact] = useState(false)

    //Возврат на предыдущую страницу
    const goBack = () => {
      navigate(-1);
    };
    const goSearch = () => {
      navigate('/search')
    }

    //Отправка сообщения в бот пользователю
    const sentAsk = () => {
      // console.log("Нажали кнопочку")
      // console.log(userData)
      // console.log("Это у нас ЭД", ad)
      const url = `https://t.me/TG_marketplaceBot/TGmarketplace?startapp=ad${ad.ad.art}`
      const message = `
      <b>Сообщение от пользователя ${ad.userData.first_name}:</b>
<i>"${mesAd}"</i>

<b>Перейти в чат c: @${ad.userData.username}</b>
`
      sendMassage(ad.ad.whatsapp, message, url)
    }

    const moment = require('moment');
    require('moment/locale/ru');

    if (ad.ad.dateAd) {
    var d = moment(ad.ad.dateAd).format('LL')}
    else {d=null}

    const rows = [
      ['Год выпуска', `${ad.ad.year} г.`],
      ['Пробег', `${ad.ad.mileage} км`],
      ['Объем двигателя', `${ad.ad.engineSize} cm3`],
      ['Цена посуточно', `${ad.ad.priceDay} baht`],
      ['Цена 1 неделя', `${ad.ad.priceWeek} baht`],
      ['Цена 2 недели', `${ad.ad.priceTwoWeek} baht`],
      ['Цена месяц', `${ad.ad.priceMounth} baht`],
      ['Цена на длительный срок', ad.ad.priceLong],
      ['Депозит', ad.ad.deposit],
    ];

    const goToProfile = () => {
      const userName = ad.ad.telegram
      const firstName = ad.ad.firstName
      const lastName = ad.ad.lastName
      navigate("/profilepage", { state: { userName, firstName, lastName, from: location } })
    }

    const goHome = () => {
      navigate('/')
    }

    return (
      <div>
          <div style={{display:"flex", flexDirection:"row", alignItems:"center", paddingTop:"10px", paddingBottom:"10px" , justifyContent:"space-between", paddingLeft:"3%", paddingRight:"3%", position:"fixed", top:"0px", zIndex:"2", width:"100%", boxSizing:"border-box"}}>
             <img onClick={isOpen ? goHome:goBack} className='imgBackForm' src={back} alt="-" />
             <img onClick={goSearch} className='imgBackForm' src={lupa} alt="-" />
        </div>
        
        <div className='eachImgContNew'>
                  {ad.ad.imageUrl && ad.ad.imageUrl.map((url, index)=> 
                  <img  style={{}} key={index} className='eachImgNew'  src={url} alt="Изображения не добавлены"/>
                  )}
              </div>
        <p className="textUderPhoto1">{ad.ad.year} {ad.ad.brand} {ad.ad.model}</p>
        <p className="textUderPhoto2">{ad.ad.priceDay} ฿</p>
        <p className="textUderPhoto3">{d}</p>
          <div className="blockMes">              
              <p className="text1Block"><img src={telegram} alt=">"/> Написать сообщение продавцу</p>
              <div className="contInputBlock">
                <input className="inputBlock" value={mesAd} onChange={(e)=>setMesAd(e.target.value)} type="text"/>
                <div className="btnInputBlock" onClick={sentAsk}>
                  <p>Отправить</p>
                </div>
               </div>
          </div>
          <div className="blockAlarm">
                <div className="eachAlarm">
                  <div className="imgContAlarm" onClick={notific}>
                     <img src={alarm} alt="1"/>
                     {visNot && <p>Подписка</p>}
                  </div>
                  <p>Оповещения</p>
                </div>
                <div className="eachAlarm">
                <a href={`https://t.me/${ad.ad.telegram}`} target='_blank' rel="noreferrer" style={{margin:'0', color:"#116466"}}>
                <div className="imgContAlarm" style={{paddingRight:"11px", paddingLeft:"9px"}}>
                  <img src={telega} alt="1"/>
                </div>
                </a>
                  <p>Сообщение</p>
                </div>
                <div className="eachAlarm">
                <div className="imgContAlarm" style={{paddingRight:"9px", paddingLeft:"11px"}} onClick={handleSave}>
                  <img src={copyBl} alt="1" />
                  {visCopy && <p>Скопировано</p>}
                </div>
                  <p>Коп. ссылку</p>
                </div>
                {!isHave ?
                <div className="eachAlarm">
                <div className="imgContAlarm" onClick={saveListAds} >
                  <img src={favorite} alt="1"/>
                  {/* {visSave && <p>Сохранено</p>} */}
                  </div>
                  <p>Сохранить</p>
                </div>:
                <div className="eachAlarm">
                   <div className="imgContAlarm" onClick={delSaveAd} style={{backgroundColor:"#cce3fd"}}>
                      <img src={favoriteBlue} alt="1"/>
                    </div>
                    <p style={{color:"#1877F2"}}>Сохранить</p>
                </div>
}
          </div>
          <div className="blockDesc">
              <p style={{fontSize:"16px", fontWeight:"800", marginBottom:"5px"}}>Описание</p>
              <p style={{margin:"0", fontSize:"15px", whiteSpace: "pre-wrap"}}>{ad.ad.discription}</p>
          </div>
          <div className="blockDesc">
              <p style={{fontSize:"16px", fontWeight:"800", marginBottom:"10px"}}>Информация о продавце</p>
              <div className="contImgProfile">
                <img src={user} alt="U" className="imgProfile2" onClick={goToProfile} />
                {ad.ad.firstName ? <p style={{fontWeight:"600"}}>{ad.ad.firstName} {ad.ad.lastName}</p>:<p style={{fontWeight:"600"}}>Пользователь телеграм</p>}
                {!haveUser ? 
                <div className="imgProfileBtn" onClick={subUser}>
                  <p>Подписаться</p>
                </div>:
                <div className="imgProfileBtn" onClick={unSubUser} style={{backgroundColor:"#cce3fd"}}>
                    <p style={{color:"#1877F2"}}>Подписка</p>
                </div>
}

          </div>
          </div>
          <div className="blockDesc" style={{marginBottom:"10px", paddingBottom:"10px"}}>
              <p style={{fontSize:"16px", fontWeight:"800", marginBottom:"5px"}}>Подробности</p>
              <table>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td className="left-column">{row[0]}</td>
              <td className="right-column">{row[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
          </div>

          {ad.ad.mapUrl && <p style={{fontSize:"16px", fontWeight:"800", marginBottom:"10px", marginLeft:"3%"}}>Предпочтения по месту встречи</p>}
          {ad.ad.mapUrl && <iframe 
          src={ad.ad.mapUrl}
          
          // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.8250488326193!2d-122.41941568468194!3d37.77492927975971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858064e1a56cd7%3A0x25fa4c5ff19db896!2sSan%20Francisco%2C%20CA!5e0!3m2!1sen!2sus!4v1614212345678!5m2!1sen!2sus" 
          width="100%" 
          height="200px" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Предпочтительное место встречи"
          />}
          </div>

    )
}
export {AdRentMoto}