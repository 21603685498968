import './CreateAd.css'
import { useNavigate } from 'react-router'
import RedMotoNew from './RedMotoNew'

import user from './user.png'

const RedAdCommon = props => {
    const navigate = useNavigate()
    const back = () =>{
        navigate(-1)
    }
//клик по кнопке Главная, переводим на главную
const gooHome = (ad) => {
        navigate('/redad', {state:ad})
      }

    return (
        <div сlassName="contCreateAd">
            <div className="headerCreateAd" >
                <p className="linkCreateAd" onClick={back}>Отмена</p>
                <p className="textHeaderCreateAd">Редактирование</p>
                <p className="linkCreateAd" onClick={gooHome}>Главная</p>
            </div>
            <div className="contNameAd">
                <img src={user} alt="U" className="imgProfileAd"/>
                {props.userData? <p className="textNameAd">{props.userData.first_name} {props.userData.last_name}</p>: <p className="textNameAd">Пользователь Телеграм</p>}
            </div>
            <RedMotoNew userData={props.userData}/>
        </div>
    )
}

export {RedAdCommon}