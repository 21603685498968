import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import { useState, useRef } from "react";
import './NewFormAdd.css'
import ButtonFormAdd from "../buttonFormAdd";
import data from '../fields.json'
import firebaseConfig from '../firebaseConfig';
// import star from "./star.png"
import { compressImage } from "../compressFunction";
// import { sendMassage } from "../sendTelegram";
import addPhoto from './addPhoto.png'


const FormAddRentTran = props => {
    //Изменение высоты Текст ареа
    // const [height, setHeight] = useState('90px');

    //Предупреждение видимость обязательных полей
    const [visWar, setVisWar] = useState(false)

    //Предупреждение нужно добавить хотя-бы одну фотографию
    const [visPhoto, setVisPhoto] = useState(false)
      
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const db = getFirestore(app);

    //Объект который будем менять с помощью инпутов. По умолчанию подтягиваем поля из json в квадратных скобках указываем категорию объекта переданную в пропсах
    const [formItems, setFormItems] = useState(data.rentTransport.inputs)

    const [download, setDownload] = useState(false)

    //Точки в конце "Загружаем изображения"
    const [dots, setDots] = useState(0);

    //Неактивность кнопки
    const [visButton, setVisButton] = useState(false)


    const scrollDown = () => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    }

    //Функция изменения значения
    const handleChange = (event) => {
      //Нужно подумать с ИД !!!!!!!!!!!!!!!!!!!!!
      setFormItems((prevState) => ({
        ...prevState,
        userId: "124kjkhjk4"
      }))
      //Нужно менять для каждой категории!!!!!!!!!!!!
      setFormItems((prevState) => ({
        ...prevState,
        underCategory: "rentMoto"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        firstName: props.userData.first_name
      }))
      setFormItems((prevState) => ({
        ...prevState,
        lastName: props.userData.last_name
      }))
      setFormItems((prevState) => ({
        ...prevState,
        telegram: props.userData.username
      }))
      setFormItems((prevState) => ({
        ...prevState,
        whatsapp: props.userData.id
      }))
      setFormItems((prevState) => ({
        ...prevState,
        dateAd: Date.now()
      }))
      //Нужно менять для каждой категории!!!!!!!!!!!!
      setFormItems((prevState) => ({
        ...prevState,
        category: "rentMoto"
      }))
      setFormItems((prevState) => ({
        ...prevState,
        gold: 1
      }))
      setFormItems((prevState) => ({
        ...prevState,
        show: false
      }))
      setFormItems((prevState) => ({
        ...prevState,
        clientNotShow: false
      }))
      setFormItems((prevState) => ({
        ...prevState,
        art: uniquImg
      }))
      const { name, value } = event.target;
      setFormItems((prevState) => ({
        ...prevState,
        [name]: value
      }));
    };
  
  //Для уникальности изображения и артикул товара
  const uniquImg = String(Date.now()).slice(-5);
  
  //Изменяем кнопку input
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    // setTextDown(null)
  };

  //Функция очистки всех полей в объекте создания документа
  const handleClear = () => {
    const clearedUser = Object.keys(formItems).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setFormItems(clearedUser);
  };

//Проверка инпутов и вывод предупреждения
const [showWarning, setShowWarning] = useState({
  brand:false,
  model:false,
  year:false,
  discription:false,
  priceDay:false,
  priceMounth:false,
})

const arrFields = ["brand","underCategory","model","year","discription","priceDay","priceMounth"]

const getTrim = () => {
  arrFields.map((field) => {
    if (!formItems[field] || formItems[field].trim() === '') {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: true
      }));
    } else {
      setShowWarning((prevWarnings) => ({
        ...prevWarnings,
        [field]: false
      }));
    }
    return null;
  });
};

//Функция создания Объявления
  const handleSave = async () => {
    getTrim()
    if (
    formItems.brand.trim() === '' || 
    // formItems.underCategory.trim()==='' || 
    formItems.model.trim() === '' || 
    formItems.year.trim() === '' ||
    formItems.discription.trim() === '' ||
    formItems.priceDay.trim() === '' ||
    formItems.priceMounth.trim() === '' 
    ){
      setVisWar(true)
    } else if (formItems.imageUrl.length<1) {
      setVisPhoto(true)
      setVisWar(false)
    }
    else {
    setVisButton(true)
    try {
      // Создаем новый документ в коллекции "users" с данными пользователя
// eslint-disable-next-line
      const docRef = await addDoc(collection(db, 'market'), {
        formItems,
        countShow:0
      });
      // Очищаем поля ввода после сохранения данных
      //ОТПРАВКА В ТЕЛЕГРАММ ИНФОРМАЦИИ ЧТО ПОСТУПИЛО НОВОЕ ОБЪЯВЛЕНИЕ, ПОЗЖЕ ИЗМЕНЮ ЧАТ И ОБЯЗАТЕЛЬНО ДОБАВЛЮ!!!!!!!!
      // sendMassage()
      // sendMassagePhoto(formItems.imageUrl[0])
      handleClear()
    } catch (error) {
      setVisButton(false)
      console.error('Ошибка при добавлении документа:', error);
    }
  }
  };
    
  //Загрузка изображений
  const handleImageUpload = async (event) => {
    setDownload(true)
    //Имитация задержки
    const intervalId = setInterval(() => {
        setDots((prevDots) => prevDots < 3 ? prevDots + 1 : 0);
      }, 300);
      setTimeout(() => {
        clearInterval(intervalId);
    }, 15000);
    

    const files = event.target.files;
    const compressedImages = await compressImage(files);
    
    try {
    // Создаем ссылки на места загрузки файлов в Firebase Storage
    const storageRefs = [];
    const getImageUrls = [];
    
    for (let i = 0; i < compressedImages.length; i++) {
      const file = compressedImages[i];
      const storageRef = ref(storage, `images/${file.name+uniquImg}`);
      storageRefs.push(storageRef);
    
      // Загружаем файлы в Firebase Storage
      await uploadBytes(storageRef, file);
    
      const imageUrl = await getDownloadURL(storageRef);
      getImageUrls.push(imageUrl);
    }
    
    setFormItems((prevState) => ({
      ...prevState,
      imageUrl: [...prevState.imageUrl, ...getImageUrls]
    }));
        
    setDownload(false)
    // setTextDown("Файлы успешно загружены!");// eslint-disable-next-line
    } catch (error) {
    console.error('Ошибка при загрузке изображений:', error);
    }
    };

    
    const removeImg = (ind) => {
      setFormItems((prevState) => ({
        ...prevState,
        imageUrl: prevState.imageUrl.filter((_, index) => index !== ind)
      }));
    }
    //добавляем марждин в депозит
    const [getMargin, setGetMargin] = useState(false)
    

    return (
      <div className='contFormAdNew'>
        <div className="contAllImg">

         {formItems.imageUrl.length>0 && formItems.imageUrl.map((url, ind)=> 
         <div key={(ind+1)/3} className="divImgAd">
           <img  src={url} alt={ind} className='imgMapAd'/> 
           <div style={{position:"absolute", top:"7px", left:"7px"}}>
              <ButtonFormAdd  onClick={() => removeImg(ind)} ind={ind}></ButtonFormAdd>
           </div>
          </div> )}

          <div className='formBtnAdNew' onClick={handleButtonClick}>
            <img src={addPhoto} alt="!"/>
            <p>Добавить фото</p>
          </div>
          </div>
          <input  style={{ display: 'none' }} ref={fileInputRef} type="file"  accept=".jpg, .jpeg, .png, .HEIF" onChange={handleImageUpload} multiple placeholder='Файлы' />
         {download && <p>Загружаем изображения{'.'.repeat(dots)}</p>}
         {visPhoto ? <p className="pWarning" style={{marginTop:"0px"}} >Необходимо добавить минимум 1 фотографию</p>:
          <p style={{color:"grey", fontSize:"12px"}}>Загрузите одно или несколько изображений. Первое загруженное фото станет главным и будет отобаржаться на обложке объявления.</p>
         }
         <div style={{position:"relative"}}>
         {formItems.brand.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Марка Транспартного средства</p>}
          <input 
            className="inputAddNew" 
            placeholder="Марка транспортного средства"
            maxLength={13}
            name="brand"
            type="text"
            value={formItems.brand}
            onChange={handleChange}
            style={formItems.brand.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.brand && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.model.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Модель</p>}
          <input 
            className="inputAddNew" 
            placeholder="Модель"
            maxLength={13}
            name="model"
            type="text"
            value={formItems.model}
            onChange={handleChange}
            style={formItems.model.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.model && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.year.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Год выпуска</p>}
          <input 
            className="inputAddNew" 
            placeholder="Год выпуска"
            maxLength={4}
            name="year"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.year}
            onChange={handleChange}
            style={formItems.year.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.year && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.mileage.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Пробег</p>}
          <input 
            className="inputAddNew" 
            placeholder="Пробег"
            maxLength={6}
            name="mileage"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.mileage}
            onChange={handleChange}
            style={formItems.mileage.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.engineSize.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Объем двигателя (см3)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Объем двигателя (см3)"
            name="engineSize"
            maxLength={5}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.engineSize}
            onChange={handleChange}
            style={formItems.engineSize.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.priceDay.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена посуточно (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена посуточно (в батах)"
            maxLength={5}
            name="priceDay"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceDay}
            onChange={handleChange}
            style={formItems.priceDay.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.priceDay && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.priceWeek.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена за 1 неделю (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена за 1 неделю (в батах)"
            name="priceWeek"
            maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceWeek}
            onChange={handleChange}
            style={formItems.priceWeek.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.priceTwoWeek.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена за 2 недели (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена за 2 недели (в батах)"
            name="priceTwoWeek"
            maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceTwoWeek}
            onChange={handleChange}
            style={formItems.priceTwoWeek.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.priceMounth.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Цена за месяц (в батах)</p>}
          <input 
            className="inputAddNew" 
            placeholder="Цена за месяц (в батах)"
            name="priceMounth"
            maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceMounth}
            onChange={handleChange}
            style={formItems.priceMounth.trim() !== '' ? {paddingTop:"15px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.priceMounth && <p className="pWarning">Поле должно быть заполено</p>}
          <div style={{position:"relative"}}>
         {formItems.priceLong.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Условия долгосрочной аренды</p>}
          <input 
            className="inputAddNew" 
            placeholder="Условия долгосрочной аренды"
            name="priceLong"
            type='text'
            value={formItems.priceLong}
            onChange={handleChange}
            maxLength={120}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.priceLong.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.deposit.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Депозит</p>}
          <input 
            className="inputAddNew" 
            placeholder="Депозит"
            name="deposit"
            type='text'
            maxLength={120}
            value={formItems.deposit}
            onChange={handleChange}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.deposit.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.mapUrl.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Ссылка на Google maps</p>}
          <input 
            className="inputAddNew" 
            placeholder="Ссылка на Google maps"
            name="mapUrl"
            type='text'
            maxLength={1200}
            value={formItems.mapUrl}
            onChange={handleChange}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.mapUrl.trim() !== '' ? {paddingTop:"15px", fontSize:"14px", marginBottom:"3px"}:{marginBottom:"3px"}}
          />
          </div>
          <p style={{color:"grey", fontSize:"12px", margin:"0", marginBottom:"15px"}}>Необязательно</p>
          <div style={{position:"relative"}}>
         {formItems.discription.trim() !== '' && <p style={{position:"absolute", fontSize:"12px", color:"gray", left:"10px", top:"-5px"}}>Описание</p>}
          <textarea
            placeholder="Описание"
            className="areaAddNew" 
            name="discription"
            maxLength={1500}
            type='text'
            value={formItems.discription}
            // style={{height:height}}
            onChange={(e)=> {handleChange(e)}}
            onFocus={()=> {scrollDown(); setGetMargin(true)}}
            onBlur={()=> setGetMargin(false)}
            style={formItems.discription.trim() !== '' ? {paddingTop:"20px", fontSize:"14px"}:{}}
          />
          </div>
          {showWarning.discription && <p className="pWarning">Поле должно быть заполено</p>}
            <p style={{color:"grey", fontSize:"12px", margin:"0", marginTop:"-10px"}}>Подробно опишите предлагаемый транспорт. Укажите нюансы которые могут быть важны для арендатора или обозначьте дополнительные условия. Не указывайте контакты, ссылки на сайты или социальные сети.</p>
          {visWar && <p className="pWarning" style={{marginTop:"15px", fontSize:"14px"}}>Проверьте правильность заполнение всех обязательных полей!</p>}

          <button className="btnAddSave" onClick={handleSave} disabled={visButton} style={getMargin ? {marginBottom:"200px"}:{}}>Опубликовать объявление</button>


        {/* <div >
        <h3>Сдать в аренду авто/байк</h3>
        <div>
      <p>Тип транспортного средства <img src={star} alt="*" className="star"/></p>
        <label>
        <input
          type="radio"
          name="underCategory"
          value="rentAuto"
          checked={formItems.underCategory === 'rentAuto'}
          onChange={handleChange}
        />
        Автомобиль 
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="underCategory"
          value="rentMoto"
          checked={formItems.underCategory === 'rentMoto'}
          onChange={handleChange}
        />
        Байк
      </label>
      {showWarning.underCategory && <p className="pWarning">Необходимо выбрать тип ТС</p>}
      <br/>
      
      <div className='allInput'>
        <div className='threeInput'>
      <div> 
        <label>
          Марка транспортного средства:
          <img src={star} alt="*" className="star"/>
          <input
            placeholder="Honda, Yamaha, Mersedes..."
            className='labelFormAd'
            maxLength={13}
            name="brand"
            type="text"
            value={formItems.brand}
            onChange={handleChange}
          />
        </label>
        {showWarning.brand && <p className="pWarning">Поле должно быть заполено</p>}
      </div>

      <div>
        <label >
          Модель:
          <img src={star} alt="*" className="star"/>
          <input
            placeholder="Click 150i, Forza, S400 4МATIC..."
            className='labelFormAd'
            maxLength={13}
            name="model"
            type="text"
            value={formItems.model}
            onChange={handleChange}
          />
        </label>
        {showWarning.model && <p className="pWarning">Поле должно быть заполено</p>}
      </div>

      <div>
        <label>
          Год выпуска:
          <img src={star} alt="*" className="star"/>
          <input
           placeholder="2022"
           maxLength={4}
          className='labelFormAd'
          name="year"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.year}
            onChange={handleChange}
          />
        </label>
        {showWarning.year && <p className="pWarning">Поле должно быть заполено (только цифры)</p>}
      </div>
      </div>

      <div className='threeInput'>
      <div>
        <label>
          Пробег:
          <input
          placeholder="13000"
          className='labelFormAd'
          maxLength={6}
          name="mileage"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.mileage}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Объем двигателя (см3):
          <input
          placeholder="2500"
          className='labelFormAd'
          name="engineSize"
          maxLength={5}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.engineSize}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Описание:
          <img src={star} alt="*" className="star"/>
          <textarea
          placeholder="Подробно опишите предлагаемый транспорт. Укажите нюансы которые могут быть важны для арендатора или обозначьте дополнительные условия. Не указывайте контакты, ссылки на сайты или социальные сети. "
          className='labelFormAdArea'
          name="discription"
          maxLength={1500}
            type='text'
            value={formItems.discription}
            style={{height:height}}
            onChange={(e)=> {setHeight(`${e.target.scrollHeight}px`); handleChange(e)}}
          />
        </label>
        {showWarning.discription && <p className="pWarning">Поле должно быть заполено</p>}
      </div>
      </div>
      </div>
      <p>Цены (в Батах):</p>
      <div className='allInput'>
      <div className='threeInput'>
      <div>
        <label>
          Посуточно (укажите минимальную цену):
          <img src={star} alt="*" className="star"/>
          <input
          placeholder="500"
          maxLength={5}
          className='labelFormAd'
          name="priceDay"
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceDay}
            onChange={handleChange}
          />
        </label>
        {showWarning.priceDay && <p className="pWarning">Поле должно быть заполено (только цифры)</p>}
      </div>
      <div>
        <label>
          1 неделя:
          <input
          placeholder="3000"
          className='labelFormAd'
          name="priceWeek"
          maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceWeek}
            onChange={handleChange}
          />
        </label>
      </div>
      </div>
      <div className='threeInput'>
      <div>
        <label>
          2 недели:
          <input
          placeholder="5000"
          className='labelFormAd'
          name="priceTwoWeek"
          maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceTwoWeek}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Месяц  :
          <img src={star} alt="*" className="star"/>
          <input
          placeholder="8000"
          className='labelFormAd'
          name="priceMounth"
          maxLength={6}
            type='number'
            onWheel={(e) => e.target.blur()}
            value={formItems.priceMounth}
            onChange={handleChange}
          />
        </label>
        {showWarning.priceMounth && <p className="pWarning">Поле должно быть заполено (только цифры)</p>}
        </div>
      </div>
      </div>
      <div>
        <label>
          Долгий период:
          <input
          placeholder="Условия при долгосрочной аренде, возможные скидки и т.д."
          className='labelFormAd'
          name="priceLong"
            type='text'
            value={formItems.priceLong}
            onChange={handleChange}
            maxLength={120}
          />
        </label>
      </div>
      <div>
        <label>
          Депозит:
          <input
          placeholder="Размер депозита, или иной предполагаемый залог"
          className='labelFormAd'
          name="deposit"
            type='text'
            maxLength={120}
            value={formItems.deposit}
            onChange={handleChange}
          />
        </label>
      </div>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
      <p>Контакты: </p>
      <img src={star} alt="*" className="star"/>
      </div>
      {showWarning.contacts && <p className="pWarning">Минимум один контакт должен быть заполнен</p>}
      <div>
        <label>
          Whatsapp:
          <input
          placeholder="Номер телефона WhatsApp: +79001112233"
          minLength={12}
          maxLength={13}
          className='labelFormAd'
          name="whatsapp"
            type='tel'
            value={formItems.whatsapp}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Telegram:
          <input
          placeholder="Ник в Telegram без @: pavel_12"
          className='labelFormAd'
          name="telegram"
          maxLength={20}
            type='text'
            value={formItems.telegram}
            onChange={handleChange}
          />
        </label>
      </div>


        <br/>
          <button className='formBtnAd' onClick={handleButtonClick}>{inpButton}</button>
         <input  style={{ display: 'none' }} ref={fileInputRef} type="file"  accept=".jpg, .jpeg, .png, .HEIF" onChange={handleImageUpload} multiple placeholder='Файлы' />
         {download && <p>Загружаем изображения{'.'.repeat(dots)}</p>}
        <div className="contMapImgAd">
         {formItems.imageUrl.length>0 && formItems.imageUrl.map((url, ind)=> 
         <div key={(ind+1)/3} className="divImgAd">
           <img  src={url} alt={ind} className='imgMapAd'/> 
           <ButtonFormAdd  onClick={() => removeImg(ind)} ind={ind}>Убрать</ButtonFormAdd>
           </div> )}
        </div>

      </div>
        {visPhoto && <p style={{color:"red"}}>Необходимо добавить минимум 1 фотографию</p>}
        {visWar && <p style={{color:"red"}}>Проверьте правильность заполнение всех обязательных полей ( * )</p>}
        <button className='formBtnAdSave' disabled={visButton} onClick={() => {window.ym(94307804,'reachGoal','addad'); handleSave()}}>Сохранить объявление</button>
      </div> */}
      
      </div>
    );
  }



export default FormAddRentTran