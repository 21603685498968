export async function sendMassagePhoto(formItems, sendTelegram) {
  if (sendTelegram) {
  try {
    const chatIds = ['-1001954630794']; // Список идентификаторов чатов
    const imageUrl = formItems.imageUrl[0]; // Замените на реальный URL изображения, которое вы хотите отправить
    const token = process.env.REACT_APP_TOKEN_TG1
    if (formItems.underCategory === 'rentMoto'){
    var messageText = `<b>Сдам ${formItems.brand} ${formItems.model}</b>\n<i>${formItems.year} г.в. </i>\n<i>Пробег - ${formItems.mileage}</i>\n<b>Цена от ${formItems.priceDay} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #аренда_байков`;
    } else if (formItems.underCategory === 'rentAuto'){
      messageText = `<b>Сдам ${formItems.brand} ${formItems.model}</b>\n<i>${formItems.year} г.в. </i>\n<i>Пробег - ${formItems.mileage}</i>\n<b>Цена от ${formItems.priceDay} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #аренда_авто`;
    } else if (formItems.underCategory === 'sellAuto') {
      messageText = `<b>Продам ${formItems.brand} ${formItems.model}</b>\n<i>${formItems.year} г.в. </i>\n<i>Пробег - ${formItems.mileage}</i>\n<b>Цена от ${formItems.price} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #продажа_авто`;
    } else if (formItems.underCategory === 'sellMoto') {
      messageText = `<b>Продам ${formItems.brand} ${formItems.model}</b>\n<i>${formItems.year} г.в. </i>\n<i>Пробег - ${formItems.mileage}</i>\n<b>Цена от ${formItems.price} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #продажа_байков`;
    } else if (formItems.category === 'servises') {
      messageText = `<b>${formItems.name}</b>\n<i>${formItems.location}</i>\n<b>Цена ${formItems.price} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #услуги`;
    } else if (formItems.category === 'stuff') {
      messageText = `<b>${formItems.name}</b>\n<i>${formItems.location}</i>\n<b>Цена ${formItems.price} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #товары`;
    } else if (formItems.category === 'sellProperty') {
      messageText = `<b>Продам ${formItems.name}</b>\n<i>${formItems.location}</i>\n<b>Цена ${formItems.price} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #продажа_недвижимости`;
    } else if (formItems.category === 'rentProperty') {
      messageText = `<b>Сдам ${formItems.name}</b>\n<i>${formItems.location}</i>\n<b>Месяц - ${formItems.priceMonth} бат</b>\n<u><a href='https://aponga.com/ad/${formItems.art}?utm_source=telegram&utm_medium=phuket&utm_campaign=apongacom'>Посмотреть объявление</a></u>\n\nВсе объявления: #аренда_надвижимости`;
    }

    const responsePromises = chatIds.map(async (chatId) => {
      const formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("caption", messageText); // Используем "caption" для текстового сообщения к изображению
      formData.append("photo", imageUrl);
      formData.append("parse_mode", "HTML"); // Add parse_mode parameter with value 'HTML'
      formData.append("disable_web_page_preview", "true"); // Add disable_web_page_preview parameter with value 'true'

      const response = await fetch(`https://api.telegram.org/${token}/sendPhoto`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        console.log(`Не удалось отправить сообщение в чат ${chatId}`);
      }
    });

    await Promise.all(responsePromises);

    window.location.reload();
  } catch (error) {
    console.log('Ошибка:', error);
    // Обработка других ошибок
  }
  } else {
    window.location.reload();
  }
}
